import { Container, Text } from "./styles";

const TitleBox = () => {
  return (
    <Container>
      <h2>Как мы это делаем</h2>
      <Text>
        Мы качественно настраиваем и ведём рекламные кампании клиентов. Ежегодно
        осваиваем новые инструменты, сервисы, форматы. Наши сотрудники получают
        сертификаты Google и Яндекс. Мы знаем, как сделать рекламу прибыльной,
        а данные о ней прозрачными для вас.
      </Text>
    </Container>
  );
};

export default TitleBox;
