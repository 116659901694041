const ProofIcon = () => {
  return (
    <svg
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="30.1386"
        cy="29.6386"
        r="29.6386"
        fill="url(#paint0_linear_1158_5622)"
      />
      <g clip-path="url(#clip0_1158_5622)">
        <path
          d="M43.4354 17.7832H16.671C15.9241 17.7832 15.3164 18.3931 15.3164 19.1428V38.5942C15.3164 39.3438 15.9241 39.9537 16.671 39.9537H33.2834C33.5219 39.9537 33.7151 39.7597 33.7151 39.5204C33.7151 39.2812 33.5219 39.0871 33.2834 39.0871H16.671C16.4002 39.0871 16.1799 38.8659 16.1799 38.5942V19.1428C16.1799 18.871 16.4003 18.6498 16.671 18.6498H43.4354C43.7063 18.6498 43.9266 18.871 43.9266 19.1428V38.5942C43.9266 38.866 43.7062 39.0871 43.4354 39.0871H39.7438C39.5053 39.0871 39.3121 39.2812 39.3121 39.5204C39.3121 39.7597 39.5053 39.9537 39.7438 39.9537H43.4354C44.1824 39.9537 44.7901 39.3438 44.7901 38.5942V19.1428C44.7901 18.3931 44.1824 17.7832 43.4354 17.7832Z"
          fill="white"
        />
        <path
          d="M42.5105 22.4145C41.2218 22.4145 40.1735 21.3624 40.1735 20.0691C40.1735 19.8297 39.9802 19.6357 39.7418 19.6357H20.3606C20.1222 19.6357 19.9289 19.8297 19.9289 20.0691C19.9289 21.3623 18.8806 22.4145 17.5919 22.4145C17.3535 22.4145 17.1602 22.6085 17.1602 22.8478V34.8892C17.1602 35.1285 17.3535 35.3225 17.5919 35.3225C18.8806 35.3225 19.9289 36.3747 19.9289 37.668C19.9289 37.9073 20.1222 38.1013 20.3606 38.1013H33.2814C33.5198 38.1013 33.7131 37.9073 33.7131 37.668C33.7131 37.4287 33.5198 37.2347 33.2814 37.2347H20.7634C20.571 35.8094 19.4438 34.678 18.0236 34.485V23.252C19.4437 23.059 20.571 21.9277 20.7634 20.5024H39.3391C39.5313 21.9276 40.6586 23.059 42.0788 23.252V34.485C40.5174 34.6972 39.31 36.0437 39.31 37.668C39.31 37.9073 39.5033 38.1013 39.7418 38.1013C39.9802 38.1013 40.1735 37.9073 40.1735 37.668C40.1735 36.3747 41.2218 35.3225 42.5105 35.3225C42.7489 35.3225 42.9422 35.1285 42.9422 34.8892V22.8478C42.9422 22.6085 42.7489 22.4145 42.5105 22.4145Z"
          fill="white"
        />
        <path
          d="M17.5981 19.6357H17.5935C17.3551 19.6357 17.1641 19.8297 17.1641 20.0691C17.1641 20.3084 17.3596 20.5024 17.5981 20.5024C17.8365 20.5024 18.0298 20.3084 18.0298 20.0691C18.0298 19.8297 17.8365 19.6357 17.5981 19.6357Z"
          fill="white"
        />
        <path
          d="M42.518 37.2349H42.5134C42.275 37.2349 42.084 37.4289 42.084 37.6682C42.084 37.9075 42.2796 38.1015 42.518 38.1015C42.7565 38.1015 42.9498 37.9075 42.9498 37.6682C42.9498 37.4289 42.7565 37.2349 42.518 37.2349Z"
          fill="white"
        />
        <path
          d="M17.5981 37.2349H17.5935C17.3551 37.2349 17.1641 37.4289 17.1641 37.6682C17.1641 37.9075 17.3597 38.1015 17.5981 38.1015C17.8365 38.1015 18.0298 37.9075 18.0298 37.6682C18.0298 37.4289 17.8365 37.2349 17.5981 37.2349Z"
          fill="white"
        />
        <path
          d="M42.5122 19.6357H42.5076C42.2691 19.6357 42.0781 19.8297 42.0781 20.0691C42.0781 20.3084 42.2737 20.5024 42.5122 20.5024C42.7506 20.5024 42.9439 20.3084 42.9439 20.0691C42.9439 19.8297 42.7506 19.6357 42.5122 19.6357Z"
          fill="white"
        />
        <path
          d="M36.5149 29.8247C34.7502 29.8247 33.3145 31.2656 33.3145 33.0368C33.3145 34.1315 33.8632 35.0995 34.6988 35.6796V40.91C34.6988 41.0698 34.7864 41.2167 34.9268 41.2921C34.9907 41.3263 35.0607 41.3433 35.1305 41.3433C35.2143 41.3433 35.2978 41.3189 35.3701 41.2705L36.5149 40.5045L37.6599 41.2705C37.7923 41.3591 37.9627 41.3674 38.1031 41.292C38.2434 41.2166 38.3311 41.0698 38.3311 40.91V35.6796C39.1666 35.0995 39.7154 34.1315 39.7154 33.0368C39.7154 31.2657 38.2797 29.8247 36.5149 29.8247ZM37.4675 40.1003L36.7543 39.6232C36.6093 39.5261 36.4204 39.5261 36.2754 39.6232L35.5623 40.1003V36.1036C35.8633 36.198 36.1833 36.2489 36.5149 36.2489C36.8465 36.2489 37.1665 36.1979 37.4675 36.1036V40.1003ZM36.5149 35.3823C35.2263 35.3823 34.1779 34.3301 34.1779 33.0368C34.1779 31.7435 35.2263 30.6913 36.5149 30.6913C37.8036 30.6913 38.8519 31.7435 38.8519 33.0368C38.8519 34.3302 37.8035 35.3823 36.5149 35.3823Z"
          fill="white"
        />
        <path
          d="M37.5324 33.1251C37.3261 33.0052 37.0619 33.0759 36.9424 33.283C36.8546 33.4351 36.6915 33.5296 36.5167 33.5296C36.2459 33.5296 36.0256 33.3085 36.0256 33.0367C36.0256 32.8612 36.1198 32.6976 36.2714 32.6094C36.4778 32.4896 36.5482 32.2245 36.4288 32.0174C36.3094 31.8102 36.0452 31.7395 35.8389 31.8594C35.4214 32.1019 35.1621 32.553 35.1621 33.0367C35.1621 33.7864 35.7698 34.3962 36.5167 34.3962C36.9986 34.3962 37.448 34.1361 37.6896 33.7173C37.8092 33.5102 37.7388 33.2451 37.5324 33.1251Z"
          fill="white"
        />
        <path
          d="M33.2866 22.8777H26.8263C26.5878 22.8777 26.3945 23.0717 26.3945 23.311C26.3945 23.5503 26.5878 23.7443 26.8263 23.7443H33.2866C33.5251 23.7443 33.7184 23.5503 33.7184 23.311C33.7184 23.0717 33.5251 22.8777 33.2866 22.8777Z"
          fill="white"
        />
        <path
          d="M26.8244 24.7302H24.9786C24.7402 24.7302 24.5469 24.9242 24.5469 25.1635C24.5469 25.4028 24.7402 25.5968 24.9786 25.5968H26.8244C27.0628 25.5968 27.2562 25.4028 27.2562 25.1635C27.2562 24.9242 27.0628 24.7302 26.8244 24.7302Z"
          fill="white"
        />
        <path
          d="M35.1302 24.7302H28.67C28.4316 24.7302 28.2383 24.9242 28.2383 25.1635C28.2383 25.4028 28.4316 25.5968 28.67 25.5968H35.1302C35.3687 25.5968 35.562 25.4028 35.562 25.1635C35.562 24.9242 35.3687 24.7302 35.1302 24.7302Z"
          fill="white"
        />
        <path
          d="M37.8985 27.9719H22.2091C21.9706 27.9719 21.7773 28.1659 21.7773 28.4052C21.7773 28.6445 21.9706 28.8385 22.2091 28.8385H37.8986C38.137 28.8385 38.3303 28.6446 38.3303 28.4052C38.3303 28.1659 38.1369 27.9719 37.8985 27.9719Z"
          fill="white"
        />
        <path
          d="M32.8241 29.8247H25.9024C25.664 29.8247 25.4707 30.0187 25.4707 30.258C25.4707 30.4973 25.664 30.6913 25.9024 30.6913H32.8241C33.0626 30.6913 33.2559 30.4974 33.2559 30.258C33.2559 30.0187 33.0626 29.8247 32.8241 29.8247Z"
          fill="white"
        />
        <path
          d="M24.0548 29.8247H22.2091C21.9706 29.8247 21.7773 30.0187 21.7773 30.258C21.7773 30.4973 21.9706 30.6913 22.2091 30.6913H24.0548C24.2933 30.6913 24.4866 30.4973 24.4866 30.258C24.4866 30.0187 24.2933 29.8247 24.0548 29.8247Z"
          fill="white"
        />
        <path
          d="M31.8996 31.6772H22.2091C21.9706 31.6772 21.7773 31.8713 21.7773 32.1106C21.7773 32.3499 21.9706 32.5439 22.2091 32.5439H31.8997C32.1381 32.5439 32.3314 32.3499 32.3314 32.1106C32.3314 31.8713 32.1381 31.6772 31.8996 31.6772Z"
          fill="white"
        />
        <path
          d="M28.6695 33.5295H22.2091C21.9706 33.5295 21.7773 33.7235 21.7773 33.9629C21.7773 34.2022 21.9706 34.3962 22.2091 34.3962H28.6695C28.9079 34.3962 29.1012 34.2022 29.1012 33.9629C29.1012 33.7235 28.9079 33.5295 28.6695 33.5295Z"
          fill="white"
        />
        <path
          d="M31.8983 33.5295H30.5138C30.2753 33.5295 30.082 33.7235 30.082 33.9629C30.082 34.2022 30.2753 34.3962 30.5138 34.3962H31.8983C32.1368 34.3962 32.3301 34.2022 32.3301 33.9629C32.3301 33.7235 32.1368 33.5295 31.8983 33.5295Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1158_5622"
          x1="27.9157"
          y1="-4.44578"
          x2="29.3976"
          y2="62.241"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BD18CB" />
          <stop offset="0.489583" stop-color="#612DAC" />
          <stop offset="1" stop-color="#8B4BED" />
        </linearGradient>
        <clipPath id="clip0_1158_5622">
          <rect
            width="29.6386"
            height="23.7108"
            fill="white"
            transform="translate(15.3184 17.7832)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProofIcon;
