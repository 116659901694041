export const integratedMarketingData = [
  {
    title: "Наш подход",
    text: [],
    bottomBox: [
      {
        title: "Маркетинговая стратегия",
        text: "Делает бренд сильнее",
      },
      {
        title: "Продажи",
        text: "Увеличивает конверсию отдела продаж",
      },
      {
        title: "Измеримое продвижение",
        text: "Увеличивает конверсию в обращения",
      },
      {
        title: "Бренд",
        text: "Доносит заложенные стратегией преимущества",
      },
    ],
  },
  {
    title: "Стратегия",
    text: [
      "Стратегия определяет эффективность дальнейших действий. Важно правильно определить преимущества и маркетинговые каналы. Так бренд быстрее станет востребованным и узнаваемым, а продвижение будет рентабельным и приносящим продажи.",
      "Маркетинговая стратегия выявляет ваши конкурентные преимущества. Определяет наиболее эффективные каналы продвижения. Рассчитывает бюджеты на маркетинг на два года вперед. Показывает, как развивать компанию, бренд, продукт.",
    ],
    bottomBox: [
      {
        title: "Позиционирование",
        text: "Собираем данные и создаём концепцию восприятия вашего продукта, которую будем доносить до аудитории",
      },
      {
        title: "Проектирование",
        text: "Разрабатываем систему маркетинга и показываем, как продвигаться различными способами два следующих года",
      },
      {
        title: "Медиапланирование",
        text: "Ставим KPI, просчитываем бюджеты и определяем ROMI. Минимальный допустимый ROMI для нас — 100%",
      },
    ],
  },
  {
    title: "Бренд-стратегия",
    text: [
      "Словесно и визуально выражаем ценности компании, определяем, как правильно доносить их.",
      "Анализируем конкурентов. Выделяем преимущества компании. Описываем позиционирование. ﻿Создаем айдентику. Подбираем площадки, чтобы донести новый бренд.",
    ],
    bottomBox: [
      {
        title: "Позиционирование",
        text: "Собираем данные и создаём концепцию восприятия вашего продукта, которую будем доносить до аудитории",
      },
      {
        title: "Идентификация",
        text: "На основе позиционирования разрабатываем имя, знак, элементы фирстиля",
      },
      {
        title: "Медиапланирование",
        text: "Определяем каналы коммуникации и создаём для них оформление в фирменном стиле",
      },
    ],
  },
  {
    title: "Контент-стратегия",
    text: [
      "Определяем интересы аудитории, чтобы эффективно донести ценности бренда﻿.",
      "Определяем темы для статей и постов. Выбираем экспертов среди ваших сотрудников. Выбираем соцсети и СМИ, где сидит ваша аудитория.",
    ],
    bottomBox: [
      {
        title: "Способы коммуникации",
        text: "Выбираем каналы и темы для общения с аудиторией: какие соцсети задействовать, о чём лучше писать, с какими лидерами мнений сотрудничать",
      },
      {
        title: "Дизайн-система",
        text: "Создаём единый с фирменным стилем дизайн для разных каналов контент-маркетинга. За счёт этого бренд легче узнается аудиторией",
      },
      {
        title: "Медиаплан",
        text: "Определяем бюджеты на контент-маркетинг и рекламу в соцсетях. Создаём контент- план. Планируем число заявок и общий охват аудитории на год вперёд",
      },
    ],
  },
  {
    title: "Бренд",
    text: [
      "Благодаря сильному бренду вас узнают и рекомендуют. Разрабатываем название и фирменный стиль. Формируем образ компании. Опираемся на ваше позиционирование. Подготавливаем руководство по фирменному стилю.",
    ],
    bottomBox: [
      {
        title: "Дизайн-система",
        text: "Выбираем каналы и темы для общения с аудиторией: какие соцсети задействовать, о чём лучше писать, с какими лидерами мнений сотрудничать",
      },
      {
        title: "Масштабируемость",
        text: "Показываем, как фирменный стиль бренда будет выглядеть на разных носителях — ручках, бланках, упаковках продукта. Создаём руководство по использованию фирменного стиля",
      },
      {
        title: "Медиаплан",
        text: "При создании названия, слоганов, дизайна отталкиваемся от ваших задач и позиционирования бренда, чтобы наши решения помогали их выполнять",
      },
    ],
  },
  {
    title: "Сайт",
    text: [
      "Центральное место для взаимодействия с аудиторией.",
      "Передаем ваши конкурентные преимущества. Увеличиваем конверсию в заявку. Делаем сайт частью дизайн-системы.",
    ],
    bottomBox: [
      {
        title: "Маркетинговый подход",
        text: "Каждый наш сайт передаёт конкурентные преимущества компании и нацелен на повышение конверсии из посещения в заявку",
      },
      {
        title: "Соединение с CRM",
        text: "Интегрируем сайт с CRM и системами аналитики. За счёт этого не упускаем ни одной заявки или звонка и сегментируем аудитории покупателей",
      },
      {
        title: "Прототипирование",
        text: "Все правки вносим и согласовываем ещё перед вёрсткой и дизайном — на этапе прототипа. За счёт этого экономим наше время и деньги заказчика",
      },
    ],
  },
  {
    title: "Измеримое продвижение",
    text: [
      "Максимально увеличиваем число заявок в рамках заданного бюджета. Приносим заявки в первый месяц. Ориентируемся на продажи. Постоянно корректируем ставки в рекламных кабинетах. Используем сервисы для автоматизации рекламы. Делаем контент, который вызывает желание купить",
      "Измеримое продвижение формирует поток обращений в отдел продаж и напрямую влияет на прибыль компании. А также накапливает цифровую аудиторию бренда, за счет чего он становится известней",
    ],
    bottomBox: [
      {
        title: "Синергия",
        text: "Увеличиваем эффективность продвижения за счет синергии с контент-маркетингом",
      },
      {
        title: "Измеримость",
        text: "Используем коллтрекинг, BigQuery, веб-аналитику и динамические отчеты, чтобы точно определять рентабельность продвижения",
      },
      {
        title: "Влияние на бренд",
        text: "Используем ваш фирменный стиль, чтобы увеличить эффективность продвижения, а также расширить аудиторию бренда",
      },
    ],
  },
  {
    title: "Реклама",
    text: [
      "Снижаем стоимость заявки. О вас узнают в поисковых системах и социальных сетях. Показываем рекламу только целевой аудитории. Делаем баннеры, посты и объявления в едином стиле. Увеличиваем продажи в офлайн и онлайн, расширяем аудиторию вашего бренда",
    ],
    bottomBox: [
      {
        title: "Увеличение продаж",
        text: "Первые заявки приходят сразу после запуска. Далее оптимизируем рекламные кампании — снижаем стоимость обращения. При отслеживании эффективности ориентируемся на продажи и ROMI",
      },
      {
        title: "Повышение узнаваемости",
        text: "Находим, тестируем и расширяем целевую аудиторию бренда. Используем единый стиль оформления для повышения узнаваемости в каналах коммуникации. Узнаваемость влияет на доверие и продажи",
      },
    ],
  },
  {
    title: "Инструменты",
    text: [],
    bottomBox: [
      {
        title: "Контекстная реклама",
        text: "Контекстную рекламу видят пользователи, которые интересуются вашим продуктом в поисковых системах",
      },
      {
        title: "Таргетинг в сетях",
        text: "Таргетированная реклама доносит преимущества продукта, формирует желание купить и узнать больше о компании",
      },
      {
        title: "Медийная реклама",
        text: "Инструмент широкого охвата, поддерживающий продажи. Хорошо подходит для работы с уже сформированным спросом на ваш продукт",
      },
    ],
  },
  {
    title: "Контент-маркетинг",
    text: [
      "Увеличиваем продажи. Улучшаем впечатление о вашем бренде. Разговариваем на одном языке с аудиторией. Создаем постоянную коммуникацию.",
      "Контент-маркетинг — это продвижение с постепенным ростом продаж и заявок",
    ],
    bottomBox: [
      {
        title: "Формирование спроса",
        text: "Увеличиваем эффективность продвижения за счет синергии с контент-маркетингом",
      },
      {
        title: "Формирование лояльности и доверия",
        text: "Интегрируем сайт с CRM и системами аналитики. За счёт этого не упускаем ни одной заявки или звонка и сегментируем аудитории покупателей",
      },
      {
        title: "Увеличение продаж и синергия",
        text: "Когда спрос сформирован, аудитория к вам лояльна и доверяет, продавать проще. Это создает синергию с другими каналами продвижения — помогает снизить стоимость продажи",
      },
    ],
  },
  {
    title: "Каналы контент-маркетинга",
    text: [],
    bottomBox: [
      {
        title: "SMM",
        text: "Построение диалога с аудиторией в социальных сетях. Большой охват, который конвертируется в заявки и продажи. Увеличение конверсии за счёт грамотной модерации",
      },
      {
        title: "Email-маркетинг",
        text: "Способ воздействовать на заинтересованных людей, продающим и полезным контентом. Отлично подходит для интернет-магазинов и для привлечения людей в блог на сайте компании",
      },
      {
        title: "Мессенджеры",
        text: "Общаемся с клиентами в чатах и каналах. Прописываем скрипты и создаём ботов, которые приносят дополнительные заявки",
      },
    ],
  },
  {
    title: "SEO",
    text: [
      "Разрабатываем стратегию для SEO. Проводим технический аудит. Улучшаем юзабилити. Уходим от санкций поисковиков.",
      "Оптимизируем сайт так, чтобы его находили в поисковиках.",
    ],
    bottomBox: [
      {
        title: "Рост целевого трафика",
        text: "Люди видят сайт в ТОПе поисковой выдачи по ключевым запросам, поэтому больше доверяют компании и с большей вероятностью совершают покупку.",
      },
      {
        title: "Усиление бренда",
        text: "Накапливаем аудиторию из органического трафика и коммуницируем с ней с помощью инструментов контент-маркетинга и рекламы.",
      },
    ],
  },
  {
    title: "Продажи",
    text: [
      "Напрямую увеличиваем доход компании и улучшаем впечатление клиентов. Соединяем продажи с маркетингом. Автоматизируем работу сотрудников через CRM. Снижаем стоимость продажи. Соединяем CRM с системами аналитики.",
      "Высокий уровень сервиса в отделе продаж улучшает отношение клиентов к бренду. А обратная связь по источникам продаж улучшает комплексное продвижение: специалисты по рекламе, контент-маркетингу и SEO знают, что приносит больше продаж.",
    ],
    bottomBox: [
      {
        title: "Автоматизируем процессы",
        text: "CRM-система с IP-телефонией и коллтрекингом собирают все заявки и определяют, откуда они приходят. Когда появляется новый клиент, программа сразу ставит менеджеру задачу с ним связаться",
      },
      {
        title: "Контролируем работу сотрудников",
        text: "Сотрудники видят в реальном времени эффективность, персональные цели и цели команды. Руководитель может посмотреть все разговоры и переписки с клиентами в CRM",
      },
      {
        title: "Повышаем лояльность клиентов",
        text: "Клиенты начинают рекомендовать вас другим и совершать повторные покупки. Потому что ваши сотрудники всегда вежливы, пунктуальны и знают о продукте всё",
      },
      {
        title: "Соединяем маркетинг и продажи",
        text: "За счёт прозрачной аналитики знаем, из каких каналов поступает больше целевых заявок и на основе этого можем оптимизировать рекламные кампании",
      },
    ],
  },
  {
    title: "Команда",
    text: [],
    bottomBox: [
      {
        title: "Маркетолог проекта",
        text: "Отвечает за результат перед клиентом. Ставит цели и выстраивает работу специалистов. Собирает информацию о компании, аудитории, конкурентах",
      },
      {
        title: "Арт-директор",
        text: "Отвечает за качество выпускаемых продуктов: сайт, рекламные баннеры, фирменный стиль",
      },
      {
        title: "Стратег",
        text: "Определяет приоритет работ по проекту. Ставит KPI, планирует ROMI и выделяет наиболее рентабельные маркетинговые каналы",
      },
      {
        title: "Дизайнер",
        text: "Делает фирменный стиль, рекламные баннеры, оформляет социальные сети. Делает сайт",
      },
      {
        title: "Контент-маркетолог",
        text: "Создает контент-стратегию, ведет социальные сети и настраивает в них рекламу, Запускает блоги и почтовые рассылки. Пишет контент на сайт",
      },
      {
        title: "Специалист по рекламе",
        text: "Настраивает контекстную, медийную и таргетированную рекламу. Управляет эффективностью рекламы",
      },
      {
        title: "Аналитик",
        text: "Настраивает сквозную аналитику, выводит данные из систем веб-аналитики и коллтрекинга в динамические отчеты",
      },
      {
        title: "Разработчик",
        text: "Делает сайт по макетам дизайнера, соединяет веб-аналитику и коллтрекинг с CRM. Создает мобильные приложения и внутренние сервисы для сайта.",
      },
    ],
  },
];
export const seoData = [
  {
    title: "Ориентируемся на продажи",
    text: [],
    bottomBox: [
      {
        title: "Релевантные запросы",
        text: "Выводим сайт в топ только по тем поисковым запросам, которые приводят покупателей. Мы не будем тратить ваш бюджет на нецелевых посетителей сайта",
      },
      {
        title: "Коммерческие факторы",
        text: "Улучшаем дизайн и интерфейс, за счет чего повышаем сайт в позициях поисковой выдачи, увеличиваем конверсию и приводим больше клиентов",
      },
      {
        title: "Рентабельность",
        text: "Если в вашей нише SEO может оказаться нерентабельным, мы предупредим об этом во время подготовки коммерческого предложения",
      },
    ],
  },
  {
    title: "Работаем в синергии ﻿с другими инструментами",
    text: [
      "Мы за комплексность в маркетинге и продвижении. Поэтому в рамках SEO улучшаем сайт так, чтобы это позитивно сказывалось и на других каналах продаж. Наши специалисты направления SEO разбираются не только в поисковой оптимизации, но и в дизайне, контекстной рекламе, классическом маркетинге и веб-аналитике.",
    ],
    bottomBox: [
      {
        title: "Дизайн",
        text: "Улучшаем юзабилити сайта. Клиенту становится легче связаться с вами, найти и оплатить товар или услугу. Это влияет и на SEO-продвижение, и на конверсию сайта в целом",
      },
      {
        title: "Проектирование и разработка",
        text: "Чтобы сайт сразу начал ранжироваться поисковиками и подниматься в выдаче, проводим SEO до его релиза. Если сайт делается с нуля, то после запуска, вам не нужно будет тратить бюджет и время на переработки для SEO. Если был старый сайт — не потеряете текущие позиции в поисковиках и трафик",
      },
      {
        title: "Контекстная реклама",
        text: "Анализируем эффективность ключевых запросов совместно со специалистами по контекстной рекламе и убираем из семантического ядра те, которые не приводят к увеличению продаж.",
      },
    ],
  },
  {
    title: "Стратегия",
    text: [
      "Перед началом работ составляем стратегию дальнейших действий. На основе неё презентуем вам план работ на несколько месяцев вперед.",
      "По итогу стратегии мы выявляем, какие улучшения нужно внедрить в первую очередь, чтобы максимально ускорить выход сайта в топ в поисковиках.",
    ],
    bottomBox: [
      {
        title: "Технический аудит",
        text: "Находим ошибки на сайте, которые мешают поисковикам правильно его сканировать и индексировать: дубли страниц, закрытые от поисковых роботов важные отделы, битые ссылки, долгая загрузка сайта.",
      },
      {
        title: "Анализ конкурентов",
        text: "Отбираем конкурентов по целевым запросам и проводим аудит сайта каждого из них. Так мы исключаем неэффективные методы продвижения и заимствуем рабочие стратегии.",
      },
      {
        title: "Анализ текущих показателей",
        text: "Смотрим на посещаемость и другие характеристики сайта, выявляем запросы, по которым на него переходят, чтобы определить наиболее приоритетные инструменты для продвижения.",
      },
      {
        title: "Контент и дизайн",
        text: "Подготавливаем Т З для копирайтеров на написание текста. Вместе с дизайнерами продумываем более удобный интерфейс сайта, добавляем на страницы формы обратной связи и другой функционал для совершения целевого действия.",
      },
      {
        title: "Кластеризация",
        text: "Создаем семантическое ядро и делим его на кластеры — чтобы под каждую группу запросов была промостраница, которая закрывает потребность пользователя — в группе товаров или услуг, информации о компании или по интересующему вопросу",
      },
    ],
  },
  {
    title: "Реализация",
    text: [
      "Мы внедряем улучшения поочередно, чтобы отслеживать их эффективность и понимать, какое решение помогло достигнуть результата.",
    ],
    bottomBox: [
      {
        title: "Руководствуемся показателями аналитики",
        text: "Проверяем, как первичная оптимизация повлияла на трафик, количество заявок и продаж. На основе этого внедряем последующие улучшения и тестируем разные инструменты SEO.",
      },
      {
        title: "Улучшаем структуру",
        text: "Чтобы увеличить число точек входа, создаем дополнительные посадочные страницы. Так пользователи быстрее найдут на вашем сайте то, что их интересует. А значит — с большей вероятностью совершат покупку.",
      },
      {
        title: "Повышаем упоминаемость",
        text: "Находим профильные площадки с хорошей репутацией в вашей и смежных сферах и договариваемся о размещении ссылок. Например, публикуем статью или добавляем компанию в рейтинг. Так к сайту повышается доверие поисковиков.",
      },
      {
        title: "Следим за семантикой",
        text: "Поддерживаем семантическое ядро в актуальном виде. Обновляем в соответствии с расширением ассортимента, услуг компании или регионов работы. Добавляем новые конкурентоспособные запросы. Так мы максимально охватываем вашу аудиторию и исключаем из трафика пользователей, которые в нее не входят.",
      },
      {
        title: "Техническая оптимизация",
        text: "Регулярно проводим аудит, чтобы исключить попадания под фильтры поисковиков. Оптимизируем сайт под мобильный поиск, следим за скоростью загрузки страниц.",
      },
    ],
  },
  {
    title: "Команда",
    text: [],
    bottomBox: [
      {
        title: "Маркетолог",
        text: "Представляет интересы клиента в проекте. Исследует компанию, продукт, конкурентов и аудиторию. Передает понимание задачи команде.",
      },
      {
        title: "SEO-специалист",
        text: "Анализирует показатели сайта, результаты работ по SEO. Пишет Т З для копирайтера, дизайнера и разработчика, определяет приоритет улучшений.",
      },
      {
        title: "Копирайтер",
        text: "Пишет и оптимизирует текст для страниц сайта, пишет статьи для профильных площадок.",
      },
      {
        title: "Дизайнер",
        text: "Улучшает интерфейс и дизайн сайта по рекомендациям SEO-специалиста.",
      },
      {
        title: "Разработчик",
        text: "Вносит технические правки на сайт, помогает внедрять новый функционал, например, новые фильтры или формы обратной связи.",
      },
    ],
  },
];
export const brandingData = [
  {
    title: "Как мы создаем из бизнеса бренд",
    text: [],
    bottomBox: [
      {
        title: "Определяем цель",
        text: "Проводим брифинг, узнаем пожелания и ограничения, определяем задачу и общее направление",
      },
      {
        title: "Анализируем",
        text: "Изучаем компанию и продукт, конкурентов, аудиторию и зарубежный опыт. Определяем наиболее важные для оформления каналы коммуникации",
      },
      {
        title: "Разрабатываем название",
        text: "Генерируем идеи для названия, выбираем лучшее, регистрируем, подбираем доменное имя и слоган",
      },
      {
        title: "Создаем логотип и фирменный стиль",
        text: "Разрабатываем знак и элементы фирменного стиля, оформляем выбранные каналы коммуникации. Создаем гайдлайн",
      },
    ],
  },
  {
    title: "Наш подход",
    text: [
      "Бренд должен нравиться целевой аудитории<br/> и выделяться среди рекламного шума",
    ],
    bottomBox: [
      {
        title: "Отличие",
        text: "Создаем визуальные и вербальные образы, которые помогают быстро и однозначно идентифицировать бренд",
      },
      {
        title: "Преимущества",
        text: "Доносим преимущества продукта и создаем добавленную стоимость через высокий уровень исполнения",
      },
      {
        title: "Масштабность",
        text: "Масштабируем на физические носители и каналы коммуникации без потери единства стиля и смысла",
      },
      {
        title: "Дизайн-система",
        text: "Сохраняем узнаваемость в каждой точке касания — сайт, социальные сети, реклама, вывеска, носители",
      },
      {
        title: "Простота",
        text: "Стремимся к легкости восприятия смыслов через отсутствие лишних отвлекающих элементов",
      },
      {
        title: "Цель",
        text: "Создаем дизайн, в основе которого лежит позиционирование бизнеса, а не просто креативное решение",
      },
    ],
  },
  {
    title: "1. Исследование",
    text: [
      "Изучаем бизнес и аудиторию, бренды конкурентов и лучшие дизайнерские решения в сфере вашей товарной категории.",
    ],
    bottomBox: [
      {
        title: "Компания",
        text: "Анализируем позиционирование компании, изучаем сильные стороны бизнеса, выделяем преимущества.",
      },
      {
        title: "Конкуренты",
        text: "Изучаем бренды конкурентов, отмечаем сильные и слабые стороны айдентики, ищем закономерности.",
      },
      {
        title: "Аудитория",
        text: "Определяем сегменты аудитории и что их объединяет. Фиксируем наиболее важные для целевой аудитории точки касания с вашей компанией.",
      },
      {
        title: "Тенденции",
        text: "Анализируем лучшие отечественные и зарубежные решения в вашей товарной категории, изучаем тенденции рынка.",
      },
      {
        title: "<span class='span__gradient'>Результат:</span>",
        text: "Определяем направление будущего стиля и наиболее важные каналы коммуникации.",
      },
    ],
  },
  {
    title: "2. Нейминг",
    text: [
      "Разрабатываем название, а также слоган или дескриптор, которые будут доносить преимущества бренда и отражать специфику бизнеса",
    ],
    bottomBox: [
      {
        title: "Разработка",
        text: "Генерируем варианты наименований, оцениваем их потенциал, выбираем 10 наиболее перспективных и презентуем вам для изучения.",
      },
      {
        title: "Согласование",
        text: "Выбираем лучший вариант вместе с вами. Проводим патентную проверку выбранного названия на уникальность.",
      },
      {
        title: "Регистрация",
        text: "Регистрируем название в качестве товарного знака и подбираем доменное имя.",
      },
      {
        title: "Слоган",
        text: "Подбираем слоган или дескриптор, который дополнит название, усилит его суть и поможет запомниться аудитории бренда.",
      },
      {
        title: "<span class='span__gradient'>Результат:</span>",
        text: "Разрабатываем уникальное название, подбираем слоган и доменное имя.",
      },
    ],
  },
  {
    title: "3. Логотип<br/> и фирменный стиль",
    text: [
      "Разрабатываем логотип и элементы фирменного стиля, которые будут привлекать внимание и нравится вашей аудитории",
    ],
    bottomBox: [
      {
        title: "Компания",
        text: "Анализируем позиционирование компании, изучаем сильные стороны бизнеса, выделяем преимущества.",
      },
      {
        title: "Конкуренты",
        text: "Изучаем бренды конкурентов, отмечаем сильные и слабые стороны айдентики, ищем закономерности.",
      },
      {
        title: "Аудитория",
        text: "Определяем сегменты аудитории и что их объединяет. Фиксируем наиболее важные для целевой аудитории точки касания с вашей компанией.",
      },
      {
        title: "Тенденции",
        text: "Анализируем лучшие отечественные и зарубежные решения в вашей товарной категории, изучаем тенденции рынка.",
      },
      {
        title: "<span class='span__gradient'>Результат:</span>",
        text: "Определяем направление будущего стиля и наиболее важные каналы коммуникации.",
      },
    ],
  },
  {
    title: "Команда",
    text: [],
    bottomBox: [
      {
        title: "Маркетолог",
        text: "Представляет интересы клиента в проекте. Знает аудиторию, передаёт понимание маркетинговых целей сайта остальной команде.",
      },
      {
        title: "Арт-директор",
        text: "Отвечает за уровень качества нейминга и дизайна Serenity, дает рекомендации и направляет дизайнеров в процессе разработки, отбирает варианты работ, проверяет и утверждает название и дизайн.",
      },
      {
        title: "Дизайнер",
        text: "Анализирует бренды конкурентов, изучает тренды в дизайне. Генерирует идеи по стилю, который формирует ассоциативные образы и передает позиционирование бренда. Разрабатывает логотип и дизайн-систему.",
      },
      {
        title: "Креативная команда",
        text: "Генерирует идеи названий, слоганов и дескрипторов, которые отвечают позиционированию компании и другим предварительно установленным критериям.",
      },
      {
        title: "Контент-маркетолог",
        text: "Изучает текущие каналы коммуникации. Находит релевантные каналы коммуникации с аудиторией, разрабатывает типы контента, составляет контент-план и инструкции по промотированию.",
      },
    ],
  },
];
export const marketingStrategyData = [
  {
    title: "Исследование",
    text: [
      "Собираем данные о конкурентах, целевой аудитории и компании для создания маркетинговой стратегии. Создаем концепцию восприятия вашего продукта, которую будем доносить до аудитории.",
    ],
    bottomBox: [
      {
        title: "Конкуренты",
        text: "Находим сильные и слабые стороны брендов конкурентов, оцениваем их маркетинговую активность.",
      },
      {
        title: "Аудитория",
        text: "Сегментируем аудиторию, определяем критерии выбора каждого сегмента и то, какие их потребности не покрывают конкуренты.",
      },
      {
        title: "Компания",
        text: "Оцениваем текущее состояние, находим возможности для развития бренда и продукта.",
      },
    ],
  },
  {
    title: "Позиционирование",
    text: [
      "Формируем позиционирование бренда. Находим и усиливаем преимущества продукта",
      "<b>Результат исследования и позиционирования:</b> выявлены сильные и слабые стороны конкурентов, вашего бизнеса; сегменты целевой аудитории. Разработана концепция позиционирования.",
    ],
    bottomBox: [
      {
        title: "Конкуренты",
        text: "Определяем позиции ваших конкурентов в сознании целевой аудитории.",
      },
      {
        title: "Целевая аудитория",
        text: "Определяем потребности аудитории на основе предпочтений и критериев выбора продукта.",
      },
      {
        title: "Восприятие",
        text: "Определяем, какое восприятие у аудитории нам нужно занять относительно конкурентов.",
      },
    ],
  },
  {
    title: "Проектирование системы маркетинга",
    text: [
      "Разрабатываем комплексную систему маркетинга, которая поможет достичь максимальной эффективности за счет синергии инструментов.",
      "<span class='span__gradient'>Результат:</span> детальное описание стратегии продвижения на 2 года.",
    ],
    bottomBox: [
      {
        title: "Сайт",
        text: "Разработка посадочной страницы, которая будет доносить преимущества компании, продукта.",
      },
      {
        title: "Брендинг",
        text: "Визуальное оформление бренда, нейминг. Создание рекламных креативов и баннеров.",
      },
      {
        title: "Реклама",
        text: "Запуск рекламных кампаний в Яндексе и Google.",
      },
      {
        title: "Контент-маркетинг",
        text: "Работы в социальных сетях и email-маркетинге.",
      },
      {
        title: "Маркетинг продаж",
        text: "Отслеживание эффективности продаж в вашей компании.",
      },
    ],
  },
  {
    title: "Digital-стратегия",
    text: [],
    bottomBox: [
      {
        title: "Постановка KPI",
        text: "Для каждого канала коммуникации определяем показатели эффективности на 2 года. Опираемся на возможности вашего бизнеса, емкость рынка и активность конкурентов.",
      },
      {
        title: "Распределение бюджетов",
        text: "Рассчитываем рекламные бюджеты, которые необходимы для достижения установленных KPI. При распределении бюджетов учитываем влияние каналов друг на друга — эффект синергии.",
      },
      {
        title: "Расчет ROMI",
        text: "На основе данных маржинальности вашего продукта рассчитываем планируемую прибыль от маркетинга. На основе затрат и прибыли от маркетинга рассчитываем ROMI. Считаем минимальный допустимый ROMI — 100%.",
      },
    ],
  },
  {
    title: "Команда",
    text: [],
    bottomBox: [
      {
        title: "Маркетолог",
        text: "Общается с вами, организует взаимодействие между вами и специалистами, собирает информацию о компании и продукте. Выстраивает эффективную работу всех специалистов, контролирует сроки и презентует каждый этап работы.",
      },
      {
        title: "Стратег",
        text: "Собирает и анализирует результаты исследований всех специалистов, находит и формулирует конкурентные преимущества, разрабатывает позиционирование, вербальную айдентику и стратегию продвижения бренда.",
      },
      {
        title: "Контент-маркетолог",
        text: "Изучает текущие каналы коммуникации, анализирует качество контента и активность конкурентов. Находит релевантные каналы коммуникации с аудиторией.",
      },
      {
        title: "Дизайнер",
        text: "Анализирует бренды конкурентов, изучает тренды в дизайне. Генерирует идеи по стилю, который формирует ассоциативные образы и передает позиционирование бренда.",
      },
      {
        title: "Аналитик",
        text: "На основе данных маржинальности вашего продукта рассчитываем планируемую прибыль от маркетинга. На основе затрат и прибыли от маркетинга рассчитываем ROMI. Считаем минимальный допустимый ROMI — 100%Изучает текущие каналы коммуникации. Находит релевантные каналы коммуникации с аудиторией, разрабатывает типы контента, составляет контент-план и инструкции по промотированию.",
      },
    ],
  },
];
export const websitesData = [
  {
    title: "1. Исследование",
    text: [
      "В результате исследования мы понимаем, как можно занять лидирующие позиции в умах потребителей.",
    ],
    bottomBox: [
      {
        title: "Компания",
        text: "Изучаем сильные стороны компании и особенности продукта. Определяем, как сделать их понятными для ваших клиентов.",
      },
      {
        title: "Конкуренты",
        text: "Анализируем сайты в вашей сфере. Смотрим, без чего нельзя обойтись и чем мы можем выделиться.",
      },
      {
        title: "Целевая аудитория",
        text: "Определяем и сегментируем аудиторию. Изучаем ее потребности, интересы, мотивы и барьеры к покупке.",
      },
    ],
  },
  {
    title: "2. Проектирование",
    text: [
      "Сразу заботимся о том, чтобы сайт был понятен и доносил нужную информацию. Для этого на прототипе продумываем путь пользователя и его взаимодействие с контентом.",
    ],
    bottomBox: [
      {
        title: "Структура",
        text: "Разрабатываем простую навигацию по сайту и вложенность страниц, выделяем посадочные страницы для продвижения основных продуктов компании.",
      },
      {
        title: "Контент",
        text: "Выстраиваем повествование на каждой странице: чтобы увлечь аудиторию, отразить преимущества, вызвать желание купить товар или услугу.",
      },
      {
        title: "SEO",
        text: "Подготавливаем семантическое ядро и адаптируем контент: чтобы сайт был готов к продвижению сразу после запуска.",
      },
      {
        title: "Прототип",
        text: "Править прототип — быстро, а готовый сайт — долго и дорого. Поэтому на этом этапе мы совместно с клиентом доводим до идеала контент, структуру и логику сайта.",
      },
    ],
  },
  {
    title: "3. Дизайн",
    text: [
      "Делаем так, чтобы сайт нравился аудитории и запоминался, закрепляя образ бренда.",
    ],
    bottomBox: [
      {
        title: "Концепция",
        text: "Собираем образы, разрабатываем концепцию дизайна, показываем идеи по анимациям.",
      },
      {
        title: "Анимации",
        text: "Обращаем внимание пользователя на важные детали и делаем взаимодействие с сайтом удобным и интуитивно понятным.",
      },
      {
        title: "Адаптивность",
        text: "Чтобы производить впечатление с любых устройств, прорабатываем версии для экранов разных размеров.",
      },
    ],
  },
  {
    title: "4. Разработка",
    text: [
      "Выпускаем сайт с интерактивным дизайном, работающими сервисами, динамическими элементами и адаптивной версткой.",
    ],
    bottomBox: [
      {
        title: "Скорость и универсальность",
        text: "Сайт быстро загружается и правильно отображается на любых экранах, браузерах и устройствах.",
      },
      {
        title: "Простое управление",
        text: "Чтобы легко добавлять товары, страницы или статьи в блог, делаем сайт на CMS: WordPress, Bitrix. Или создаем свою систему управления.",
      },
      {
        title: "Функционал и интеграции",
        text: "Добавляем на сайт системы оплаты, умные корзины товаров, персональные рекомендации. Интегрируем популярные решения и создаем свои.",
      },
    ],
  },
  {
    title: "Результаты",
    text: [],
    bottomBox: [
      {
        title: "Продажи",
        text: "Увеличивается конверсия из посещения сайта в обращение, что влияет на продажи.",
      },
      {
        title: "Бренд",
        text: "Повышается статус компании, аудитория начинает лучше относиться к вашему бренду.",
      },
      {
        title: "Статус",
        text: "Новый сайт привлекает внимание и выделяет компанию среди конкурентов.",
      },
    ],
  },
  {
    title: "Команда",
    text: [],
    bottomBox: [
      {
        title: "Проектировщик",
        text: "Разрабатывает прототип. Продумывает структуру сайта и каждой страницы.",
      },
      {
        title: "SEO-специалист",
        text: "Создаёт семантическое ядро сайта. Дает рекомендации контент-маркетологу и проектировщику, чтобы готовый сайт был SEO-оптимизирован.",
      },
      {
        title: "Контент-маркетолог",
        text: "Собирает информацию о компании и продукте и на основе нее составляет контент.",
      },
      {
        title: "Дизайнер",
        text: "Работает над визуальной составляющей. Превращает прототип в макет с расставленными акцентами.",
      },
      {
        title: "Фронтэнд-разработчик",
        text: "Переводит макеты в код. Заботится о качественном отображении сайта в разных браузерах и на экранах разных устройств.",
      },
      {
        title: "Бэкэнд-разработчик",
        text: "Интегрирует сайт с системой управления и сторонними сервисами: например, CRM и системами онлайн-оплаты.",
      },
      {
        title: "Маркетолог",
        text: "Представляет интересы клиента в проекте. Знает аудиторию, передаёт понимание маркетинговых целей сайта остальной команде.",
      },
      {
        title: "Проджект-менеджер",
        text: "Управляет командой и ставит контрольные точки специалистам. Пишет Т З для разработчиков.",
      },
    ],
  },
];
export const contentMarketingData = [
  {
    title: "SMM",
    text: [
      "Главный и самый популярный метод построения диалога с аудиторией. SMM продвигает ваш бренд в социальных сетях, налаживает коммуникации с тысячами или десятками тысяч людей и позволяет быстро продавать.",
    ],
    bottomBox: [
      {
        title: "Охват",
        text: "С удобными инструментами рекламы в социальных сетях можно за несколько месяцев рассказать десяткам тысяч людей о вашем бренде.",
      },
      {
        title: "Контент",
        text: "В социальных сетях любят живой контент: настоящие фото и видео, эмоциональные тексты. Создавать его легче, чем экспертный, более серьезный.",
      },
      {
        title: "Вовлечение",
        text: "Аудитория в социальных сетях хочет общаться. Она оставляет комментарии, делится постами и участвует в конкурсах. Это легко использовать на благо бренда.",
      },
    ],
  },
  {
    title: "Email-маркетинг",
    text: [
      "Способ воздействовать на людей, которые уже заинтересовались компанией: оставили свой email на сайте.",
    ],
    bottomBox: [
      {
        title: "Плюс одно касание",
        text: "Триггерные письма помогут аудитории перейти на следующий этап воронки продаж. Они напомнят клиенту, что он оставил товар в корзине. Или не закончил покупку.",
      },
      {
        title: "Плюс одно напоминание",
        text: "Ручные рассылки повышают доверие аудитории и интерес к компании. В них можно дать людям узнать о вас больше и поделиться полезными материалами.",
      },
      {
        title: "Плюс к продажам",
        text: "Каждое письмо — напоминание аудитории о ваших преимуществах и возможности купить у вас. С каждым качественным письмом будут появляться новые клиенты.",
      },
    ],
  },
  {
    title: "Корпоративный блог",
    text: [
      "Полноценное СМИ, которое привлекает клиентов на ваш сайт. Блог вызывает желание у соискателей работать с вами, а у клиентов — покупать ваши услуги.",
    ],
    bottomBox: [
      {
        title: "Синергия с SMM",
        text: "Анонсы материалов из блога в социальных сетях привлекают на сайт аудиторию, особенно если их рекламировать.",
      },
      {
        title: "Свобода формата",
        text: "Захватывайте внимание аудитории любым способом — делайте тесты, игры, подборки видео — все, что позволяет технический потенциал блога.",
      },
      {
        title: "Польза для SEO",
        text: "Полезные пользователям страницы поисковики выводят в самый верх. В итоге на ваш сайт станут заходить чаще и по целевым запросам.",
      },
    ],
  },
  {
    title: "Другие форматы коммуникаций",
    text: [],
    bottomBox: [
      {
        title: "Лидеры мнений",
        text: "Пользователи доверяют рекомендациям, которые дают их любимые блогеры. Мы находим тех, за кем следит ваша аудитория и вместе делаем полезную рекламу.",
      },
      {
        title: "СМИ",
        text: "Увеличиваем узнаваемость бренда многократно — делаем партнёрские материалы для СМИ, которые читает или смотрит ваша аудитория.",
      },
      {
        title: "Мессенджеры",
        text: "Общаемся с клиентами в чатах и каналах. Создаем ботов, которые приносят дополнительные заявки.",
      },
      {
        title: "Вебинары и семинары",
        text: "Раскрываем вашу экспертность через мероприятия. Это — один из самых эффективных способов приобрести фанатов бренда.",
      },
      {
        title: "Белые книги",
        text: "Если о вашем продукте раньше никто не слышал, мы можем рассказать о нём в белой книге — выжимке, которая описывает, как решить проблему вашей ЦА.",
      },
      {
        title: "Информационные проекты",
        text: "Онлайн-журналы, объединения со СМИ и экспертами — спецпроекты, чтобы в короткий срок увеличить вашу аудиторию",
      },
    ],
  },
  {
    title: "Для сложных продуктов разрабатываем контент-стратегию",
    text: [
      "<p class = 'line__break'></p>",
      "Высокая конкуренция. Новый продукт или бренд, которые выходят на рынок. В этих случаях мы делаем контент-стратегию.",
      "<p class = 'includes'>В нее входят:</p>",
    ],
    bottomBox: [
      {
        title: "Исследование",
        text: "Анализ бренда, конкурентов, аудитории и продукта.",
      },
      {
        title: "Контент-план",
        text: "Подбор площадок и составление календаря публикаций.",
      },
      {
        title: "Медиаплан",
        text: "Расчет бюджетов на ведение контент-маркетинга, рентабельности разных каналов и KPI на полгода.",
      },
      {
        title: "Дизайн",
        text: "Дизайн каналов контент-маркетинга, соблюдающий фирменный стиль бренда.",
      },
    ],
  },
  {
    title: "Наш подход",
    text: [],
    bottomBox: [
      {
        title: "Ориентация на продажи",
        text: "Мы делаем продающий контент, чтобы увеличить рентабельность контент-маркетинга здесь и сейчас. Публикуем сообщения об акциях, промоутируем посты, в которых можно оставить заявку.",
      },
      {
        title: "Усиление маркетинга",
        text: "Люди, которые увидели пост в социальных сетях, прочитали статью в онлайн-газете или подписались на рассылку, охотнее перейдут по ссылке из рекламы или вобьют название компании в поисковике, когда вспомнят о вашем продукте.",
      },
      {
        title: "Накапливание цифровой аудитории",
        text: "Собираем сегменты пользователей: из социальных сетей, посетителей сайта, читателей рассылки. Они — цифровая аудитория бренда, которую можно делить по этапам воронки продаж и работать с ней во всех каналах маркетинга.",
      },
    ],
  },
  {
    title: "Наша работа делится на четыре этапа",
    text: [],
    bottomBox: [
      {
        title: "Исследование",
        text: "Создаем контент-стратегию или базовое маркетинговое исследование.",
      },
      {
        title: "Разработка контента",
        text: "Создаем и публикуем материалы в каналах контент-маркетинга.",
      },
      {
        title: "Продвижение",
        text: "Делаем так, чтобы этот контент увидел максимально широкий сегмент ЦА.",
      },
      {
        title: "Аналитика",
        text: "Смотрим на результаты ведения контент-маркетинга. Планируем улучшения.",
      },
    ],
  },
  {
    title: "1. Исследование",
    text: [],
    bottomBox: [
      {
        title: "Компания и конкуренты",
        text: "Анализируем контент-маркетинг компании и конкурентов. Отбираем эффективные каналы и форматы публикаций.",
      },
      {
        title: "Аудитория",
        text: "Смотрим, чем интересуется ЦА. Сегментируем аудиторию по социально-демографическим признакам.",
      },
      {
        title: "Продукт",
        text: "Узнаем боли аудитории относительно продукта. Выделяем его сильные и слабые стороны. Определяем, о чем будем рассказывать.",
      },
    ],
  },
  {
    title: "2. Разработка контента",
    text: [],
    bottomBox: [
      {
        title: "Интервью",
        text: "Общаемся с вами и узнаём подробно о сфере вашей деятельности. На основе этого продумываем темы и делаем качественные посты и статьи.",
      },
      {
        title: "Фото- и видеопродакшн",
        text: "Преподносим ваш продукт при помощи фото и видео. За счёт этого делаем контент, который запоминается и усиливает впечатление от бренда.",
      },
      {
        title: "Оптимизация",
        text: "Добавляем в статьи ключевые слова, обрабатываем фотографии, корректируем посты по вашим правкам.",
      },
    ],
  },
  {
    title: "3. Продвижение",
    text: [],
    bottomBox: [
      {
        title: "Реклама",
        text: "Увеличиваем охват через соцсети: делаем баннеры, скрытые рекламные посты. Итог: увеличение переходов людей на сайт прямо из соцсетей и через другие форматы.",
      },
      {
        title: "Email-маркетинг",
        text: "Увеличиваем охват материалов из блога и рассказываем об акциях через рассылку. Привлекаем подписчиков рассылки на сайт, в блог и социальные сети.",
      },
      {
        title: "Внешние площадки",
        text: "Адаптируем выпущенный контент под другие медиа. Делаем ссылки на публикации на внешних сайтах или сообществах в социальных сетях.",
      },
    ],
  },
  {
    title: "4. Аналитика",
    text: [],
    bottomBox: [
      {
        title: "Подсчет показателей",
        text: "Считаем число ассоциированных конверсий — сколько людей переходило на сайт сразу или через какое-то время после посещения соцсетей. Смотрим, как часто бренд искали в поисковиках.",
      },
      {
        title: "Изменение<br/> контент-плана",
        text: "Каждый месяц анализируем эффективность разных тем и рубрик. Оставляем те, что нравятся аудитории и приносят переходы на сайт. Тестируем новые.",
      },
      {
        title: "Умные отчёты",
        text: "Делаем отчеты в Power BI. Они автоматически обновляются и показывают эффективность каждого из каналов контент-маркетинга. Вам достаточно лишь ссылки на отчет, чтобы всегда иметь актуальные данные под рукой.",
      },
    ],
  },
  {
    title: "Команда",
    text: [],
    bottomBox: [
      {
        title: "Маркетолог проекта",
        text: "Руководит специалистами, организует встречи с клиентом. Участвует в креативах, помогает определить аудиторию.",
      },
      {
        title: "Контент-маркетолог",
        text: "Разрабатывает контент-стратегию, составляет контент-план. Ведёт социальные сети, блоги, пишет статьи и рассылки.",
      },
      {
        title: "Таргетолог",
        text: "Занимается продвижением постов. Работает с рекламой в социальных сетях. Запускает email-рассылки.",
      },
      {
        title: "Аналитик",
        text: "Подключает и настраивает системы аналитики. Создаёт data-pipeline и автоматические отчёты в Power BI. Дает рекомендации по оптимизации рекламных кампаний.",
      },
      {
        title: "Дизайнер",
        text: "Делает оформление социальных сетей, макеты email-рассылок. Дает рекомендации по работе с визуальным контентом, оформлению сетки в Инстаграме.",
      },
    ],
  },
];


export const contentStrategyData = [
  {
    title: "Наш подход",
    text: [],
    bottomBox: [
      {
        title: "Определяем ЦА",
        text: "Находим вашу целевую аудиторию, делим её на сегменты и составляем подробное описание по социально-демографическим признакам.",
      },
      {
        title: "Изучаем информационные потребности",
        text: "Определяем, в каких соцсетях и СМИ сидит аудитория, что читает и смотрит. Её стереотипы относительно продукта, преграды и страхи на пути к покупке.",
      },
      {
        title: "Отстраняемся от конкурентов",
        text: "Оцениваем коммуникации конкурентов с аудиторией. Фокусируемся на тех сторонах продукта, о которых они ещё не рассказывали.",
      },
      {
        title: "Используем «воронку вовлеченности»",
        text: "Распределяем аудиторию по степени с заинтересованности брендом и продуктом. Подбираем для каждой из них рубрики контента.",
      },
    ],
  },
  {
    title: "Наша работа делится на четыре этапа",
    text: [],
    bottomBox: [
      {
        title: "Исследование",
        text: "Собираем информацию, на основе которой будем делать стратегию.",
      },
      {
        title: "Выбор каналов",
        text: "Определяем каналы и инструменты для ведения контент-маркетинга.",
      },
      {
        title: "Дизайн-концепция",
        text: "Создаем запоминающийся стиль ведения и оформления каждого канала.",
      },
      {
        title: "Медиаплан",
        text: "Делаем календарь публикаций. Планируем KPI и стоимость продвижения.",
      },
    ],
  },
  {
    title: "1. Исследование",
    text: [
      "Анализируем целевую аудиторию, конкурентов, компанию и продукт. Собираем информацию, которой будем делиться с пользователями.",
    ],
    bottomBox: [
      {
        title: "Целевая аудитория",
        text: "Анализируем ЦА по социально-демографическим признакам: пол, возраст, место проживания, семейный статус, деятельность, доход. Собираем аудитории для ретаргетинга — им будем показывать рекламу в социальных сетях.",
      },
      {
        title: "Компания и продукт",
        text: "Собираем информацию, которой будем делиться с аудиторией. Вычисляем основные боли относительно продукта. Например для клиники пластической хирургии: какие мифы останавливают людей делать операции.",
      },
      {
        title: "Конкуренты",
        text: "Определяем, какие каналы используют конкуренты, какой контент публикуют и с какой частотой. Смотрим, сколько людей переходит к ним на сайт из внешних площадок и социальных сетей.",
      },
    ],
  },
  {
    title: "2. Выбор каналов продвижения",
    text: [
      "Определяем рентабельность и цели разных каналов. Подбираем для них темы и рубрики. Продумываем механики работы с аудиторией: конкурсы, игры, розыгрыши, опросы.",
    ],
    bottomBox: [
      {
        title: "Корпоративный блог",
        text: "Продумываем внешний вид и интерфейс блога: пишем ТЗ для дизайнера и разработчика. Определяем форматы и цели публикаций: привлекать сотрудников, рассказывать о компании, продавать услуги.",
      },
      {
        title: "Социальные сети",
        text: "Основной инструмент распространения контента и увеличения охвата. Продумываем темы публикаций. Определяем, как будем привлекать аудиторию к покупкам, увеличивать её лояльность бренду.",
      },
    ],
  },
  {
    title: "",
    text: [],
    bottomBox: [
      {
        title: "Лидеры мнений и внешние площадки",
        text: "Находим СМИ и блогеров, которых любит ваша аудитория. Определяем количество зрителей и читателей, узнаём эффективность рекламы. Рассчитываем стоимость показа и заявки в результате публикации.",
      },
      {
        title: "Email-рассылки",
        text: "Продумываем типы и рубрики контента для рассылок. Создаём одно письмо. Делаем на основе него шаблон и готовим для отправления по базе адресатов.",
      },
      {
        title: "Другие каналы",
        text: "Делаем чат-боты в мессенджерах для быстрых покупок. Пишем белые книги, чтобы подробно рассказать о новом продукте. Организовываем вебинары и семинары для живого контакта с аудиторией. Разрабатываем информационные проекты, чтобы создать мощную опору для вашего бренда.",
      },
    ],
  },
  {
    title: "3. Дизайн-концепция",
    text: ["Делаем бренд узнаваемым за счет сильных визуальных образов."],
    bottomBox: [
      {
        title: "Единый стиль",
        text: "Делаем похожий дизайн для всех каналов коммуникаций. Благодаря этому пользователи ассоциируют коммуникации на разных площадках с вашим брендом.",
      },
      {
        title: "Дизайн писем",
        text: "Делаем полезные и красивые рассылки, которые хорошо отображаются в разных почтовых клиентах и привлекают клиентов.",
      },
      {
        title: "Дизайн социальных сетей и баннеров",
        text: "Создаём аватар и футер и баннеры для страниц в социальных сетях. Делаем шаблоны для рубрик и продумываем сетку в инстаграме.",
      },
    ],
  },
  {
    title: "4. Медиаплан и эффективность",
    text: [
      "Завершающий этап. На основе всей полученной информации составляем медиаплан. Он включает в себя KPI, планирование бюджетов и времени.",
    ],
    bottomBox: [
      {
        title: "Контент-план",
        text: "Контент-план — это календарь публикаций с рубриками и темами для каждого канала.",
      },
      {
        title: "Постановка KPI",
        text: "Ставим измеримые цели, которых хотим достичь при помощи контент-маркетинга.",
      },
      {
        title: "Бюджеты",
        text: "Рассчитываем рентабельность каналов продвижения. Определяем стоимость дальнейших работ, партнерских публикаций, рекламы в социальных сетях.",
      },
    ],
  },
  {
    title: "Результат",
    text: [],
    bottomBox: [
      {
        title: "Набор инструментов",
        text: "Нужные функции рекламного кабинета, механики конкурсов в социальных сетях, сервисы аналитики, форматы публикаций, сценарии триггерных рассылок.",
      },
      {
        title: "Медиаплан",
        text: "Смета расходов с учетом показателей аналитики сайта, данных о ЦА и конкурентах. Задачи со сроками, метриками и способами исполнения.",
      },
      {
        title: "Контент-план и редполитика",
        text: "Календарь публикаций, список рубрик и тем для каждого канала. Рекомендации по созданию контента для авторов. Правила общения с аудиторией и обработки негатива.",
      },
      {
        title: "Дизайн",
        text: "Узнаваемое оформление всех каналов контент-маркетинга. Шаблоны постов в формате PSD. Аватары и шапки для социальных сетей.",
      },
    ],
  },
  {
    title: "Команда",
    text: [],
    bottomBox: [
      {
        title: "Маркетолог",
        text: "Организует работу специалистов. Определяет портрет целевой аудитории, отношение к продукту, препятствия на пути к покупке.",
      },
      {
        title: "Контент-маркетолог",
        text: "Анализирует текущие каналы контент-маркетинга компании и конкурентов. Составляет медиаплан и контент-план с рубриками и темами.",
      },
      {
        title: "Аналитик",
        text: "Измеряет текущую эффективность контент-маркетинга. Дает рекомендации по планированию бюджетов и выставлению KPI.",
      },
      {
        title: "Дизайнер",
        text: "Верстает письма для email-рассылки. Создает макеты и шаблоны для оформления социальных сетей. Дает рекомендации по оформлению сетки в Instagram.",
      },
      {
        title: "Стратег",
        text: "Собирает и анализирует результаты исследований всех специалистов, находит и формулирует конкурентные преимущества, разрабатывает позиционирование, вербальную айдентику и стратегию продвижения бренда.",
      },
    ],
  },
];


export const smmData = [
  {
    title: "",
    text: [],
    bottomBox: [
      {
        title: "<span class='span__gradient'>Рекламные инструменты</span>",
        text: "Повышаем узнаваемость бренда с помощью промотирования контента. Создаем аудитории ретаргетинга, регулярную коммуникацию с аудиторией бренда.",
      },
      {
        title: "<span class='span__gradient'>Стратегия продвижения</span>",
        text: "Определяем цели продвижения, сроки, каналы коммуникации и способы общения с аудиторией. Анализируем результаты и корректируем стратегию.",
      },
      {
        title: "<span class='span__gradient'>Качественный контент</span>",
        text: "Ориентируемся на формирование репутации бренда: создаем экспертный образ с помощью полезного контента и высокого качества исполнения.",
      },
    ],
  },
  {
    title: "1. Исследование",
    text: [
      "Определяем цели вашего бизнеса, потребности аудитории, сильные и слабые стороны конкурентов.",
    ],
    bottomBox: [
      {
        title: "Брифинг",
        text: "Проводим брифинг, чтобы определить цели и задачи бизнеса. Анализируем текущие результаты продвижения компании. Эти данные учитываем при построении стратегии.",
      },
      {
        title: "Аудитория",
        text: "Для продуктивного взаимодействия с целевой аудиторией определяем потребности, боли и интересы, анализируем отношение аудитории к бренду. Аудиторию сегментируем по группам.",
      },
      {
        title: "Конкуренты",
        text: "Изучаем поведение конкурентов в digital-среде: стиль общения, позиционирование, основные площадки коммуникаций. Выясняем, на какой контент лучше реагируют пользователи.",
      },
    ],
  },
  {
    title: "2. SMM-стратегия",
    text: [
      "Работу над стратегией начинаем с позиционирования компании. Это помогает сформулировать пользу бренда для аудитории. На основе выявленных потребностей, определяем информационные рубрики и создаем контент-план.",
    ],
    bottomBox: [
      {
        title: "Позиционирование",
        text: "Отвечаем на вопрос «чем вы лучше конкурентов?». Далее определяем социальные медиа для коммуникации и тональность общения с аудиторией.",
      },
      {
        title: "Аудитория бренда",
        text: "Формируем аудиторию бренда для регулярной коммуникации. Сохраняем в списки ретаргетинга тех, кто взаимодействовал с компанией в социальных сетях, на сайте, потенциальных и текущих клиентов в базе CRM-системы.",
      },
      {
        title: "Контент-план",
        text: "Выбираем ключевые рубрики и форматы контента для достижения поставленных целей. Создаем календарный план публикаций.",
      },
    ],
  },
  {
    title: "3. Оформление",
    text: [
      "Разрабатываем единый стиль для всех социальных сетей, за счет чего усиливаем запоминаемость вашего бренда.",
    ],
    bottomBox: [
      {
        title: "Оформление и наполнение сообществ",
        text: "Создаем или улучшаем фирменный стиль социальных сетей: обложки, аватар и шаблоны для постов. Наполняем страницу сообщества обсуждениями, информацией о компании, контактами.",
      },
      {
        title: "Редакционная политика",
        text: "Разрабатываем уникальный стиль общения с аудиторией: определяем тональность, основной посыл, принципы и правила создания контента.",
      },
    ],
  },
  {
    title: "4. Реализация",
    text: [
      "Запускаем контент, настраиваем рекламу, смотрим аналитику, вносим корректировки, пишем отчеты.",
    ],
    bottomBox: [
      {
        title: "Запуск и продвижение",
        text: "На основе SMM-стратегии создаем и адаптируем контент под разные социальные сети: «Вконтакте», «Одноклассники», Facebook, Instagram.",
      },
      {
        title: "Аналитика",
        text: "Используем измеримый подход к контент-маркетингу — оцениваем результаты с точки зрения продаж. Анализируем участие социальных сетей в других каналах коммуникации и продаж — отслеживаем путь клиента от прочтения поста до покупки на сайте.",
      },
      {
        title: "Корректировки",
        text: "Улучшаем стратегию на основе полученных результатов. Смотрим, какие темы и каналы показывают лучшие результаты — редактируем контент-план, перераспределяем бюджеты и отказываемся от менее эффективных типов контента.",
      },
    ],
  },
  {
    title: "Отчеты",
    text: ["Составляем подробный отчет о проделанной работе."],
    bottomBox: [
      {
        title: "Охват аудитории",
        text: "Сколько пользователей хоть раз увидели наш контент.",
      },
      {
        title: "Трафик из социальных сетей",
        text: "Сколько посетителей перешло из социальных сетей на ваш сайт.",
      },
      {
        title: "Активное ядро аудитории",
        text: "Какая часть аудитории бренда регулярно взаимодействует с контентом.",
      },
      {
        title: "Конверсии трафика",
        text: "Сколько из перешедших на сайт людей оставили заявку или позвонили. Затем — сколько совершили покупку.",
      },
      {
        title: "Влияние на продажи в других каналах",
        text: "Какие пользователи оставляют заявку после рекламы или SEO после посещения SMM-каналов.",
      },
      {
        title: "Индекс вовлеченности",
        text: "Насколько создаваемый контент интересен целевой аудитории: число лайков, комментариев, репостов.",
      },
    ],
  },
  {
    title: "Результаты",
    text: [],
    bottomBox: [
      {
        title: "Образ и репутация бренда",
        text: "Создаем экспертный образ бренда и вызываем доверие аудитории с помощью профессионального контента.",
      },
      {
        title: "Узнаваемость бренда",
        text: "Выстраиваем регулярную коммуникацию с помощью промотирования: напоминаем о себе и охватываем новую аудиторию.",
      },
      {
        title: "Увеличение продаж",
        text: "Подогреваем интерес клиента к продукту компании. Создаем контент для каждого этапа воронки продаж и показываем его соответствующей аудитории.",
      },
    ],
  },
  {
    title: "Команда",
    text: [],
    bottomBox: [
      {
        title: "Контент-менеджер",
        text: "Делает публикации согласно контент-плану: пишет текст, обрабатывает изображения, проводит конкурсы. Настраивает и ведет рекламу. Дает обратную связь подписчикам в сообщениях или комментариях сообществ.",
      },
      {
        title: "Контент-маркетолог",
        text: "Изучает текущие и находит релевантные каналы коммуникации с аудиторией, разрабатывает типы контента, составляет контент-план и инструкции по промотированию.",
      },
      {
        title: "Аналитик",
        text: "Подключает метрики к сообществам. Оценивает трафик на сайт из социальных сетей. Дает рекомендации по продвижению, выделяет наиболее виральные рубрики контента или публикации.",
      },
      {
        title: "Маркетолог",
        text: "Общается с вами, организует взаимодействие между вами и специалистами. Выстраивает эффективную работу всех специалистов, контролирует сроки и презентует каждый этап работы.",
      },
      {
        title: "Дизайнер",
        text: "Анализирует бренды конкурентов, изучает тренды в дизайне. Генерирует идеи по стилю, который формирует ассоциативные образы и передает позиционирование бренда. Разрабатывает логотип и дизайн-систему.",
      },
    ],
  },
];
export const videoProductionData = [
  {
    title: "Наш подход",
    text: [],
    bottomBox: [
      {
        title: "Определяем цель",
        text: "Проводим брифинг, узнаем пожелания и ограничения, определяем задачу и общее направление.",
      },
      {
        title: "Исследуем среду",
        text: "Определяем, как преподнести продукт, чтобы отразить характер бренда и вызвать интерес у целевой аудитории — анализируем конкурентов и тенденции, российский и зарубежный рынок.",
      },
      {
        title: "Продумываем концепцию",
        text: "Генерируем идею, собираем мудборд и пишем сценарий. Описываем нюансы организации в техническом задании.",
      },
      {
        title: "Проводим съемку",
        text: "Полностью берем на себя организационные и технические вопросы: формируем сильную команду, находим локации, собираем образы и лично контролируем процесс на площадке.",
      },
      {
        title: "Готовим материал к размещению",
        text: "Из отснятого видеоматериала выбираем лучшие кадры, которые отправляем на пост-продакшн: цветокоррекцию, ретушь, монтаж, проработку звука.",
      },
      {
        title: "Результат",
        text: "Готовый к публикации и решению поставленной задачи видеоролик, который поможет отразить позиционирование бренда и преимущества продукта.",
      },
    ],
  },
  {
    title: "Исследование",
    text: [
      "Изучаем целевую аудиторию, конкурентов и актуальные тенденции в сфере вашей товарной категории.",
    ],
    bottomBox: [
      {
        title: "Компания",
        text: "Анализируем позиционирование компании, изучаем сильные стороны бизнеса, выделяем преимущества и способы транслировать их на аудиторию.",
      },
      {
        title: "Аудитория",
        text: "Понимаем вкусы, интересы и потребности целевой аудитории. Выясняем, на какой контент и в каких каналах коммуникации реагирует лучше всего.",
      },
      {
        title: "Конкуренты",
        text: "Находим сильные и слабые стороны визуальной составляющей конкурентов, находим закономерности и понимаем, как отстраниться.",
      },
      {
        title: "Тенденции",
        text: "Изучаем актуальные стили и приемы визуализации, чтобы грамотно адаптировать их под продукт.",
      },
    ],
  },
  {
    title: "Концепция",
    text: [
      "Находим сильные и слабые стороны визуальной составляющей конкурентов, находим закономерности и понимаем, как отстраниться.",
      "<b>Концепция</b> — это единый образ, который помогает бренду налаживать тесный визуальный контакт с аудиторией: запоминаться, вызывать эмоции и желание, формировать лояльность.",
    ],
    bottomBox: [
      {
        title: "Идея",
        text: "Опираясь на исследование описываем концепцию съемки — визуальные образы, возможные интерпретации и реакцию аудитории. Понимаем как наиболее выгодно продемонстрировать продукт.",
      },
      {
        title: "Визуализация",
        text: "Собираем идеи для концепции в мудборд — добавляем примеры цвета, света, настроения и стиля. Возможные ассоциации с продуктом и примеры их использования.",
      },
      {
        title: "Техническое задание и сценарий",
        text: "Описываем, что необходимо для проведения съемки: состав команды, количество смен, стиль съемки, тип локации, оборудование. Создаем полноценный сценарий и рассчитываем точный бюджет.",
      },
    ],
  },
  {
    title: "Реализация",
    text: [
      "Проводим съемочный день по разработанному плану, чтобы получить тот результат, который задумывали. Лично контролируем процесс на площадке.",
    ],
    bottomBox: [
      {
        title: "Подготовка",
        text: "Опираясь на исследование описываем концепцию съемки — визуальные образы, возможные интерпретации и реакцию аудитории. Понимаем как наиболее выгодно продемонстрировать продукт.",
      },
      {
        title: "Съемочный день",
        text: "Собираем идеи для концепции в мудборд — добавляем примеры цвета, света, настроения и стиля. Возможные ассоциации с продуктом и примеры их использования.",
      },
      {
        title: "Обработка",
        text: "Описываем, что необходимо для проведения съемки: состав команды, количество смен, стиль съемки, тип локации, оборудование. Создаем полноценный сценарий и рассчитываем точный бюджет.",
      },
    ],
  },
  {
    title: "Наши отличия",
    text: [
      "Создаем цепляющие видеоролики для решения маркетинговых задач. Используем преимущества комплексного подхода — знания и опыт в работе с разными каналами коммуникации.",
    ],
    bottomBox: [
      {
        title: "Универсальность",
        text: "Создаем контент, который будет уместен в любых каналах коммуникации: на сайте, билбордах, в социальных сетях и рекламе.",
      },
      {
        title: "Комплексность",
        text: "Работаем в синергии с другими услугами агентства — знаем, как улучшить эффективность сайта, SMM или рекламы с помощью видеоряда.",
      },
      {
        title: "Польза",
        text: "Ориентируемся на позиционирование и основные цели бизнеса, чтобы контент не просто нравился аудитории — помогал продвижению и продажам.",
      },
      {
        title: "Индивидуальность",
        text: "Помогаем выделить продукт среди конкурентов и запомниться аудитории за счет маркетингового подхода — полного погружения в продукт, целевую аудиторию и задачи продвижения.",
      },
    ],
  },
  {
    title: "Команда",
    text: [],
    bottomBox: [
      {
        title: "Маркетолог",
        text: "Отвечает за взаимодействие со специалистами. Контролирует работу команды. Принимает участие в исследовании.",
      },
      {
        title: "Продюсер",
        text: "Организует подготовку и съемочный процесс, контролирует реализацию на соответствие концепции и задаче. Управляет бюджетом.",
      },
      {
        title: "Арт-директор",
        text: "Поддерживает уровень качества при подготовке и на площадке: участвует в кастинге команды, контролирует композицию кадра, утверждает результат.",
      },
      {
        title: "Креативная команда",
        text: "Генерирует идеи концепции, собирает мудборд, пишет сценарий.",
      },
      {
        title: "Техническая команда",
        text: "Создает кадр: отвечает за композицию, свет и звук, спецэффекты и работу оборудования.",
      },
      {
        title: "Художественная команда",
        text: "Оформляет кадр: подбирает локации, образы, реквизит, отвечает за дизайн.",
      },
    ],
  },
];
export const contextualAdvertisingData = [
  {
    title:
      "Контекстную рекламу видят пользователи, которые интересуются вашим продуктом в поисковых системах.",
    text: [],
    bottomBox: [
      {
        title: "Горячая аудитория",
        text: "Вы выбираете, кому показывать рекламу. Это могут быть те, кто ищет продукт в поисковиках, ваши клиенты, посетители сайта или точек продаж в офлайн.",
      },
      {
        title: "Мультиформатность",
        text: "Объявления в поисковиках и на медийных площадках (рекламные сети Google и Яндекса).",
      },
      {
        title: "Мгновенный результат",
        text: "Первые заявки появляются сразу после запуска. Затем их стоимость можно снижать, чтобы за те же деньги привлекать еще больше клиентов.",
      },
    ],
  },
  {
    title: "1. Анализ",
    text: [],
    bottomBox: [
      {
        title: "Компания и конкуренты",
        text: "Анализируем ваш бизнес и текущие рекламные кампании. Исследуем семантическое ядро, креативы и уникальные предложения конкурентов.",
      },
      {
        title: "Целевая аудитория",
        text: "Определяем, на что опирается целевая аудитория при выборе товара, возможные препятствия на пути к покупке.",
      },
      {
        title: "<span class='span__gradient'>Результат</span>",
        text: "Получаем данные, на основе которых разрабатываем стратегию, строим прогнозы и определяем KPI.",
      },
    ],
  },
  {
    title: "2. Стратегия",
    text: [],
    bottomBox: [
      {
        title: "Семантическое ядро и аудитории",
        text: "Делим ЦА на сегменты. Выделяем аудитории для таргетинга и кластеры семантики, по которым будем показывать рекламу.",
      },
      {
        title: "Подходящие инструменты",
        text: "Подробный анализ помогает определить, как двигаться дальше. На основе него мы выбираем инструменты рекламы и форматы объявлений, которые будем использовать.",
      },
      {
        title: "<span class='span__gradient'>Результат</span>",
        text: "Карта мыслей со всеми направлениями развития контекстной рекламы. От неё мы отталкиваемся, когда запускаем рекламную кампанию.",
      },
    ],
  },
  {
    title: "3. Запуск",
    text: [],
    bottomBox: [
      {
        title: "Запоминающиеся рекламные кампании",
        text: "Прорабатываем креативы. Воздействуем на пользователей визуальными образами и словом. Адаптируем рекламный посыл под разные форматы и потребительские ситуации.",
      },
      {
        title: "Прозрачная аналитика",
        text: "Подключаем Яндекс. Метрику, TopMail, Google Analytics, Коллтрекинг. В них настраиваем цели для отслеживания заявок с сайта и с телефона. Благодаря этому вы видите, как реклама влияет на клиентов и в интернете и офлайн.",
      },
      {
        title: "Понятные отчёты",
        text: "Делаем отчеты в Power BI. Они автоматически обновляются, и включают все данные о рекламной кампании. Вам не нужно искать число конверсий в Яндекс. Метрике или Google Analytics, всё выводится в одном окне.",
      },
      {
        title: "<span class='span__gradient'>Результат</span>",
        text: "Рекламные кампании готовы к запуску и дальнейшей оптимизации. Настроены ставки и стратегии, загружены списки ключевых и минус-слов. Баннеры и тексты объявлений добавлены в рекламные системы. Настроена прозрачная отчётность и аналитика.",
      },
    ],
  },
  {
    title: "4. Оптимизация",
    text: [],
    bottomBox: [
      {
        title: "Улучшаем кампании",
        text: "Чистим нецелевые ключевые слова, убираем сегменты аудиторий или тестируем новые. Корректируем ставки и на основе этого перераспределяем бюджет. <p class = 'line__break'></p> Когда данных достаточно, подключаем оптимизаторы: К50, Origami, Alytics. Они помогают более эффективно работать с рекламными кампаниями. Благодаря этому мы быстрее достигаем заданных KPI.",
      },
      {
        title: "Анализируем и планируем",
        text: "Каждый месяц показываем вам достигнутые результаты. Продумываем темы и направления для креативов. Если есть возможность, внедряем новые каналы для рекламы.",
      },
    ],
  },
  {
    title: "Результаты",
    text: [],
    bottomBox: [
      {
        title: "Качественная рекламная кампания",
        text: "Мы продумываем дальнейшие пути развития. Учитывая нишу и специфику бизнеса, выбираем нужные форматы и инструменты. Определяем и сегментируем целевую аудиторию.",
      },
      {
        title: "Результаты в понятном виде",
        text: "Единый отчёт в Power BI, который автоматически обновляется каждый день. С ним понятно, насколько кампания приблизилась к выполнению KPI и что делать дальше. Также вы сами можете посмотреть результаты кампаний в кабинетах Яндекс.Метрики и Google Analytics.",
      },
      {
        title: "Влияние на маркетинг компании",
        text: "За счет единого стиля в дизайне и сильных рекламных посылов повышается узнаваемость бренда. Те, кто не был знаком с компанией, становятся частью ее цифровой аудиторией. А те, кто был, начинают лучше относиться к бренду и продукту.",
      },
    ],
  },
  {
    title: "Наши преимущества",
    text: [],
    bottomBox: [
      {
        title: "Ориентируемся на прибыль",
        text: "Не ставим в приоритет увеличение синтетических показателей рекламы: охвата и количества кликов. Наша основная цель — снижение стоимости заявки и увеличение ROMI.",
      },
      {
        title: "Комплексность",
        text: "Совмещаем контекстную рекламу с таргетингом, контент-маркетингом, SEO. В результате компания получает прибыль из разных каналов маркетинга и увеличивает узнаваемость.",
      },
      {
        title: "Погружаемся в маркетинг",
        text: "Специалист ведет до шести проектов. Поэтому он уделяет внимание каждой рекламной кампании — чаще их анализирует и приносит качественные конверсии из заявки.",
      },
    ],
  },
  {
    title: "Команда",
    text: [],
    bottomBox: [
      {
        title: "Специалист по рекламе",
        text: "Анализирует рекламу конкурентов, продумывает стратегию. Настраивает и ведёт кампании. Корректирует ставки, подключает сервисы для автоматизации.",
      },
      {
        title: "Аналитик",
        text: "Подключает и настраивает системы аналитики. Создаёт data-pipeline и автоматические отчёты в Power BI. Дает рекомендации по оптимизации рекламных кампаний.",
      },
      {
        title: "Лидер направления",
        text: "Курирует работу специалиста и аналитика. Дает рекомендации по использованию инструментов, помогает при разработке стратегии.",
      },
      {
        title: "Дизайнер и копирайтер",
        text: "Прорабатывают креативы для баннеров, выбирают УТП. Создают текст и визуальную составляющую баннеров.",
      },
      {
        title: "Маркетолог",
        text: "Представляет агентство перед клиентом. Организует работу специалистов. Участвует в исследованиях и креативах.",
      },
    ],
  },
];
export const targetedAdvertisingData = [
  {
    title:
      "Таргетированная реклама — это когда ваша аудитория узнает о том,<br/> что ей нужны вы.",
    text: [],
    bottomBox: [
      {
        title: "Повышаем продажи",
        text: "Генерируем заявки из социальных сетей: подталкиваем аудиторию к взаимодействию, подогреваем интерес к услуге и не даем вас забыть. Ориентируемся на интересы и «боли» аудитории, поведение в интернете и офлайн.",
      },
      {
        title: "Узнаваемость и запоминаемость",
        text: "Находим, тестируем и расширяем целевую аудиторию бренда. Используем единый стиль оформления для повышения узнаваемости в каналах коммуникации.",
      },
      {
        title: "Прозрачная аналитика",
        text: "Анализируем рекламные кампании ориентируясь на заявки — отказываемся от неэффективных решений и грамотно распределяем бюджет.",
      },
    ],
  },
  {
    title: "1. Исследование",
    text: [
      "Работу по запуску таргетированной рекламы проводим в четыре этапа: исследование, стратегия, реализация и оптимизация.",
    ],
    bottomBox: [
      {
        title: "Бриф",
        text: "Первый этап — брифинг. На нем мы знакомимся с вашим бизнесом и продуктом — определяем цели и задачи, а также план дальнейших действий.",
      },
      {
        title: "Аудитория",
        text: "Исследуем целевую аудиторию вашего бренда: интересы, «боли», поведение в интернете и офлайн. В результате показываем рекламу только тем, кого она заинтересует.",
      },
      {
        title: "Конкуренты",
        text: "Изучаем поведение конкурентов в социальных сетях, чтобы понять, в чем можем их превзойти.",
      },
    ],
  },
  {
    title: "2. Стратегия",
    text: [
      "На основе исследования составляем стратегию — карту мыслей, в которой отражаем необходимую для запуска информацию:",
    ],
    bottomBox: [
      {
        title: "Каналы коммуникации",
        text: "На основе исследования определяем, где и каким образом взаимодействовать с целевой аудиторией.",
      },
      {
        title: "Сегменты аудиторий",
        text: "Собираем и сегментируем аудиторию. Сохраняем в списки ретаргетинга тех, кто проявил интерес к бренду.",
      },
      {
        title: "Дизайн-концепции",
        text: "Формируем единый образ бренда, который используем в будущих рекламных кампаниях.",
      },
      {
        title: "Креативные посылы",
        text: "На основе потребностей и болей аудитории формируем креативные сообщения, которые транслируем через баннеры и тексты.",
      },
    ],
  },
  {
    title: "3. Реализация",
    text: [],
    bottomBox: [
      {
        title: "Контент и дизайн",
        text: "Создаем контент и визуальные образы для рекламной кампании. Адаптируем под задачи, аудиторию и потребительские ситуации.",
      },
      {
        title: "Запуск",
        text: "Настраиваем и запускаем рекламную кампанию: загружаем аудитории, баннеры и тексты, устанавливаем ставки и стратегию показов.",
      },
      {
        title: "Аналитика",
        text: "Подключаем к счетчикам аналитики Google, Яндекс и TopMail для отслеживания конверсий из социальных сетей. Делаем понятные отчеты в Power BI.",
      },
    ],
  },
  {
    title: "4. Оптимизация",
    text: [],
    bottomBox: [
      {
        title: "Улучшаем кампании",
        text: "Тестируем десятки вариантов настройки рекламных кампаний и подключаем сервисы оптимизации, чтобы находить самые эффективные решения, увеличивать охват и снижать стоимость заявки.",
      },
      {
        title: "Анализируем и планируем",
        text: "Корректируем стратегию продвижения по итогам месяца: например, продумываем новые темы и направления для креативов, находим дополнительные аудитории. <br/> О результатах и планах на следующий месяц рассказываем вам на отчетной встрече.",
      },
    ],
  },
  {
    title: "Результаты",
    text: [],
    bottomBox: [
      {
        title: "Влияние на маркетинг компании",
        text: "За счет единого стиля в дизайне и сильных рекламных посылов повышается узнаваемость бренда. Те, кто не был знаком с компанией, становятся частью ее цифровой аудиториии. А те, кто был, начинают лучше относиться к бренду и продукту.",
      },
      {
        title: "Качественная рекламная кампания",
        text: "Мы продумываем дальнейшие пути развития. Учитывая нишу и специфику бизнеса, выбираем нужные форматы и инструменты. Определяем и сегментируем целевую аудиторию.",
      },
      {
        title: "Результаты в понятном виде",
        text: "Единый отчет в Power BI, который ежедневно автоматически обновляется. С ним понятно, насколько кампания приблизилась к выполнению KPI и что делать дальше. Также вы сами можете посмотреть результаты кампаний в кабинетах Яндекс. Метрики и Google Analytics.",
      },
    ],
  },
  {
    title: "Команда",
    text: [],
    bottomBox: [
      {
        title: "Таргетолог",
        text: "Проводит исследование конкурентов и аудитории, продумывает стратегию. Настраивает и ведет кампании. Корректирует ставки, подключает сервисы для автоматизации.",
      },
      {
        title: "Дизайнер",
        text: "Разрабатывает дизайн-концепцию и создает и адаптирует под нужный формат визуальное сопровождение рекламных кампаний.",
      },
      {
        title: "Копирайтер",
        text: "Создает тексты рекламных объявлений с учетом задачи, целевой аудитории и каналов коммуникации.",
      },
      {
        title: "Аналитик",
        text: "Подключает и настраивает системы аналитики. Создаёт data-pipeline и автоматические отчёты в Power BI. Дает рекомендации по оптимизации рекламных кампаний.",
      },
      {
        title: "Маркетолог",
        text: "Представляет агентство перед клиентом. Организует работу специалистов. Участвует в исследованиях и креативах.",
      },
    ],
  },
];
export const salesMarketingData = [
  {
    title: "Не управляем<br/> продажами — теряем прибыль",
    text: ["Мы увеличиваем доход компании за счет улучшения отдела продаж."],
    bottomBox: [
      {
        title: "Автоматизируем рутинные процессы",
        text: "CRM-система с IP-телефонией и коллтрекингом собирают все заявки и определяют, откуда они приходят. Когда появляется новый клиент, программа сразу ставит менеджеру задачу с ним связаться.",
      },
      {
        title: "Контролируем работу сотрудников",
        text: "Сотрудники концентрируются на работе с клиентами и не тратят время на рутинные задачи. Они видят в реальном времени персональные цели и цели команды. Вдобавок CRM хранит все разговоры и переписки с клиентами.",
      },
      {
        title: "Соединяем маркетинг и продажи",
        text: "Прозрачная аналитика показывает, сколько заявок принесли каждое ключевое слово, пост или баннер. На основе этих данных можно оптимизировать рекламные кампании.",
      },
      {
        title: "Повышаем лояльность клиентов",
        text: "Клиенты начинают рекомендовать вас другим и совершать повторные покупки. Потому ваши сотрудники всегда вежливы, пунктуальны и знают о продукте всё.",
      },
    ],
  },
  {
    title: "Маркетинг продаж с нуля",
    text: [
      "В результате создаём конкурентоспособный и современный отдел продаж. Помогаем улучшать его в дальнейшем.",
    ],
    bottomBox: [
      {
        title: "Исследование",
        text: "Анализируем работу отдела продаж вашей компании и конкурентов. Нанимаем тайных покупателей, общаемся с сотрудниками. Определяем барьеры ЦА на пути к покупке. Собираем информацию для проектирования нового отдела продаж.",
      },
      {
        title: "Проектирование",
        text: "Разрабатываем длинную воронку продаж: от первого касания до совершения сделки и повторных покупок. Подбираем CRM, сервисы коллтрекинга и IP-телефонии. Продумываем систему мотивации сотрудников.",
      },
      {
        title: "Внедрение",
        text: "Подключаем и настраиваем инструменты. Настраиваем прозрачную аналитику: менеджеры видят рентабельность сайта, рекламы и всех остальных маркетинговых коммуникаций. Также обучаем сотрудников: работать в CRM, общаться с клиентами по новым скриптам, отслеживать командную и личную эффективность.",
      },
      {
        title: "Поддержка и сопровождение",
        text: "Анализируем маркетинговые коммуникации, вводим улучшения в отдел продаж. Анализируем эффективность сотрудников и помогаем распределять расходы на маркетинг. Подготавливаем отчёты в Power BI, которые автоматически обновляются.",
      },
    ],
  },
  {
    title: "Проектирование и оптимизация",
    text: [
      "Если у вас уже есть CRM, но вы желаете повысить эффективность отдела продаж.",
      "В результате создаём конкурентоспособный и современный отдел продаж. Помогаем улучшать его в дальнейшем.",
    ],
    bottomBox: [
      {
        title: "Аудит",
        text: "Исследуем текущую систему и выявляем слабые места. Например, сотрудники не ставят задачи или клиенты уходят на этапе заявки. Проектируем будущие улучшения и выбираем те, что будем внедрять.",
      },
      {
        title: "Внедрение улучшений",
        text: "На основе аудита оптимизируем отдел продаж. Обновляем скрипты для менеджеров, улучшаем системы аналитики, добавляем контрольные точки в воронку продаж.",
      },
      {
        title: "Следующий этап",
        text: "Мы заново смотрим на всю последовательность от первого касания до продажи и определяем, что можно улучшить. Так, с каждым этапом, повышаем конверсию из заявки в продажу и рентабельность расходов на рекламу.",
      },
    ],
  },
  {
    title: "Команда",
    text: [],
    bottomBox: [
      {
        title: "Маркетолог проекта",
        text: "Представляет интересы клиента в проекте. Исследует компанию, продукт, конкурентов и аудиторию. Передает понимание задачи команде.",
      },
      {
        title: "Маркетинг-аналитик",
        text: "Проектирует воронку продаж, пишет скрипты и разрабатывает систему мотивации сотрудников, настраивает CRM.",
      },
      {
        title: "Разработчик/BI-аналитик",
        text: "Настраивает коллтрекинг и IP-телефонию. Соединяет их с CRM и системами веб-аналитики. Проектирует и создает data-pipeline для прозрачной аналитики. Все показатели эффективности выводит в отчеты в Power BI.",
      },
    ],
  },
];
export const copywritingData = [
  {
    title: "Наш подход",
    text: [],
    bottomBox: [
      {
        title: "Мы ориентированы на продажи",
        text: "Мы создаем продающий контент, чтобы увеличить рентабельность контент-маркетинга здесь и сейчас.",
      },
      {
        title: "Адаптируем контент",
        text: "Разрабатываем контент-стратегию, пишем грамотные и понятные тексты для вашей аудиторию",
      },
      {
        title: "Упаковываем контент",
        text: "Занимаемся подбором визуального сопровождения, расставлением наиболее важных акцентов",
      },
    ],
  },
  {
    title: "Наша работа делится на 4 этапа",
    text: [],
    bottomBox: [
      {
        title: "Исследование",
        text: "Создаем контент-стратегию или базовое маркетинговое исследование.",
      },
      {
        title: "Продвижение",
        text: "Делаем так, чтобы этот контент увидел максимально широкий сегмент ЦА.",
      },
      {
        title: "Разработка контента",
        text: "Создаем и публикуем материалы в каналах контент-маркетинга.",
      },
      {
        title: "Аналитика",
        text: "Смотрим на результаты ведения контент-маркетинга. Планируем улучшения.",
      },
    ],
  },
  {
    title: "1. Исследование",
    text: [],
    bottomBox: [
      {
        title: "Компания и конкуренты",
        text: "Анализируем контент-маркетинг компании и конкурентов. Отбираем эффективные каналы и форматы публикаций.",
      },
      {
        title: "Аудитория",
        text: "Смотрим, чем интересуется ЦА. Сегментируем аудиторию по социально-демографическим признакам.",
      },
      {
        title: "Продукт",
        text: "Узнаем боли аудитории относительно продукта. Выделяем его сильные и слабые стороны. Определяем, о чем будем рассказывать.",
      },
    ],
  },
  {
    title: "2. Разработка контента",
    text: [],
    bottomBox: [
      {
        title: "Интервью",
        text: "Общаемся с вами и узнаём подробно о сфере вашей деятельности. На основе этого продумываем темы и делаем качественные посты и статьи.",
      },
      {
        title: "Фото- и видеопродакшн",
        text: "Преподносим ваш продукт при помощи фото и видео. За счёт этого делаем контент, который запоминается и усиливает впечатление от бренда.",
      },
      {
        title: "Оптимизация",
        text: "Добавляем в статьи ключевые слова, обрабатываем фотографии, корректируем посты по вашим правкам.",
      },
    ],
  },
  {
    title: "3. Продвижение",
    text: [],
    bottomBox: [
      {
        title: "Реклама",
        text: "Увеличиваем охват через соцсети: делаем баннеры, скрытые рекламные посты. Итог: увеличение переходов людей на сайт прямо из соцсетей и через другие форматы.",
      },
      {
        title: "Email-маркетинг",
        text: "Увеличиваем охват материалов из блога и рассказываем об акциях через рассылку. Привлекаем подписчиков рассылки на сайт, в блог и социальные сети.",
      },
      {
        title: "Внешние площадки",
        text: "Адаптируем выпущенный контент под другие медиа. Делаем ссылки на публикации на внешних сайтах или сообществах в социальных сетях.",
      },
    ],
  },
  {
    title: "4. Аналитика",
    text: [],
    bottomBox: [
      {
        title: "Подсчет показателей",
        text: "Считаем число ассоциированных конверсий — сколько людей переходило на сайт сразу или через какое-то время после посещения соцсетей. Смотрим, как часто бренд искали в поисковиках.",
      },
      {
        title: "Изменение контент-плана",
        text: "Каждый месяц анализируем эффективность разных тем и рубрик. Оставляем те, что нравятся аудитории и приносят переходы на сайт. Тестируем новые.",
      },
      {
        title: "Умные отчёты",
        text: "Делаем отчеты в Power BI. Они автоматически обновляются и показывают эффективность каждого из каналов контент-маркетинга. Вам достаточно лишь ссылки на отчет, чтобы всегда иметь актуальные данные под рукой.",
      },
    ],
  },
  {
    title: "Команда",
    text: [],
    bottomBox: [
      {
        title: "Маркетолог проекта",
        text: "Руководит специалистами, организует встречи с клиентом. Участвует в креативах, помогает определить аудиторию.",
      },
      {
        title: "Контент-маркетолог",
        text: "Разрабатывает контент-стратегию, составляет контент-план. Ведёт социальные сети, блоги, пишет статьи и рассылки.",
      },
      {
        title: "Копирайтер",
        text: "Пишет и оптимизирует текст для страниц сайта, пишет статьи для профильных площадок.",
      },
      {
        title: "Таргетолог",
        text: "Занимается продвижением постов. Работает с рекламой в социальных сетях. Запускает email-рассылки.",
      },
      {
        title: "Аналитик",
        text: "Подключает и настраивает системы аналитики. Создаёт data-pipeline и автоматические отчёты в Power BI. Дает рекомендации по оптимизации рекламных кампаний.",
      },
      {
        title: "Дизайнер",
        text: "Делает оформление социальных сетей, макеты email-рассылок. Дает рекомендации по работе с визуальным контентом, оформлению сетки в Инстаграме.",
      },
    ],
  },
];
export const designData = [
  {
    title: "Наш подход",
    text: [
      "Компании, которые выделяются на фоне конкурентов благодаря уникальному дизайну, вырываются в лидеры индустрии. В разработке дизайна мы используем качественный подход, с помощью которого подчеркивается самобытность бренда.",
    ],
    bottomBox: [
      {
        title: "Организация",
        text: "В дизайне должна быть точка фокуса, нечто цепляющее внимание потребителя. Элементы дизайна должны быть организованы, обладать иерархией, таким образом, чтобы внимание зрителя направлялось от наиболее важных элементов к менее значимым. Все элементы дизайна должны быть сбалансированы.",
      },
      {
        title: "Типографика",
        text: "Типографика в дизайне играет ключевую роль. Важно максимально качественно подобрать шрифты, которые будут сочетаться между собой, передавать необходимое для бренда настроение и стиль.",
      },
      {
        title: "Стилистика",
        text: "В зависимости от задач бренда, его ценностей, в качественном дизайне необходимо грамотно подобрать цвета и цветовые схемы. Они влияют на восприятие всего дизайна, на ощущение настроения от него: серьезности и профессионализма, элегантности и утонченности, веселья и игривости или любого другого, в зависимости от поставленных целей и специфики компании.",
      },
      {
        title: "Результативность",
        text: "Дизайн должен быть не только визуально эстетичным, но и эффективно продавать.",
      },
    ],
  },
  {
    title: "1.Прототипирование",
    text: [
      "Основная цель прототипов — проверка последовательности пути пользователя и выявления проблем, которые могут возникнуть на этом пути.",
    ],
    bottomBox: [
      {
        title: "Преимущества",
        text: "Экономия времени в разработке дизайна, упрощение работы дизайнера, упрощение взаимодействия с исполнителями, повышение продаж.",
      },
      {
        title: "Аудит",
        text: "Анализируем потребности и особенности компании. Разрабатываем уникальные торговые предложения, которые будут выделяться среди конкурентов рынка.",
      },
      {
        title: "Структура",
        text: "Создаем проработанную структуру и качественную организацию сайта, которые будут удобны пользователю, что приведет к повышению продаж.",
      },
      {
        title: "Прототип",
        text: "Создаем статичные и интерактивные прототипы, которые дадут полное представление о будущей системе, ее интерфейсе и функционале.",
      },
    ],
  },
  {
    title: "2. Веб-дизайн",
    text: [
      "Разрабатываем удобные интерфейсы, оформляем визуальную часть сайта, передающую уникальность бренда. Улучшаем пользовательский опыт, который повышает конверсию.",
    ],
    bottomBox: [
      {
        title: "Аналитика",
        text: "Анализируем ЦА сайта, чтобы понять ее мотивации и препятствия, производим анализ поведения пользователей с помощью систем веб-аналитики, чтобы создать простой и удобный интерфейс.",
      },
      {
        title: "Композиция",
        text: "Разрабатываем целостную композицию сайта, которая будет выглядеть гармонично и выполнять главные задачи: удобство в использовании, увеличение конверсии.",
      },
      {
        title: "Цвет",
        text: "Подбираем цветовые схемы, подходящие под задачи бренда. Используем те цвета, которые соответствуют настроениям бренда, его ценностям, атмосфере и уникальности.",
      },
      {
        title: "Типографика",
        text: "Подбираем шрифтовые пары, которые отражают сущность бренда, его стилистику и аутентичность.",
      },
    ],
  },
  {
    title: "3. Дизайн-системы",
    text: [
      "Дизайн-система позволяет автоматизировать процессы и существенно экономить время при создании проектов; повышает узнаваемость бренда. Органичная система позволяет пользователю интуитивно ориентироваться по всему продукту.",
    ],
    bottomBox: [
      {
        title: "Стратегия",
        text: "Определяем потребности клиента, анализируем основные цели и задачи продукта.",
      },
      {
        title: "Философия",
        text: "Анализируем ценности компании, преобразовываем философию бренда в цифровой вид.",
      },
      {
        title: "Система",
        text: "Разрабатываем шаблоны и компоненты дизайн-системы.",
      },
    ],
  },
  {
    title: "4. Брендинг",
    text: [
      "Создаем образ компании, ее уникальный стиль, повышаем ценность бренда и лояльность к нему. С помощью качественного брендинга повышается узнаваемость компании.",
    ],
    bottomBox: [
      {
        title: "Анализ",
        text: "Анализируем ЦА, ее мотивации и боли, изучаем рынок, чтобы выделить сильные и слабые стороны конкурентов.",
      },
      {
        title: "Концепция",
        text: "Подчеркиваем уникальность компании, разрабатываем концепции бренда, которые будут выделяться среди конкурентов.",
      },
      {
        title: "Дизайн",
        text: "Раскрываем идеи и ценности компании, с помощью визуального дизайна: создания стиля, подбора фирменных цветов, подбора шрифтов, либо создания шрифтов с нуля.",
      },
    ],
  },
  {
    title: "5. Графический дизайн",
    text: [
      "С помощью графического дизайна мы подчеркиваем уникальность бренда, формируем его узнаваемость, создаем эмоциональное взаимодействие: формируем ассоциации, связанные с брендом.",
    ],
    bottomBox: [
      {
        title: "Идентичность",
        text: "Разрабатываем логотип бренда, его айдентику. Также занимаемся разработкой логобука, брендбука, фирменного стиля.",
      },
      {
        title: "Полиграфия",
        text: "Создаем макеты полиграфии: плакаты, постеры, листовки, каталоги, баннеры.",
      },
      {
        title: "Соцсети",
        text: "Оформляем аккаунты соцсетей. Качественное оформление аккаунта повышает лояльность клиентов, подчеркивает уникальный стиль бренда, который запоминается и выделяется среди конкурентов.",
      },
    ],
  },
  {
    title: "Команда",
    text: [],
    bottomBox: [
      {
        title: "Маркетолог",
        text: "Представляет интересы клиента в проекте. Знает аудиторию, передаёт понимание маркетинговых целей сайта остальной команде.",
      },
      {
        title: "Арт-директор",
        text: "Отвечает за уровень качества нейминга и дизайна Serenity, дает рекомендации и направляет дизайнеров в процессе разработки, отбирает варианты работ, проверяет и утверждает название и дизайн.",
      },
      {
        title: "Дизайнер",
        text: "Анализирует бренды конкурентов, изучает тренды в дизайне. Генерирует идеи по стилю, который формирует ассоциативные образы и передает позиционирование бренда. Разрабатывает логотип и дизайн-систему.",
      },
      {
        title: "Креативная команда",
        text: "Генерирует идеи названий, слоганов и дескрипторов, которые отвечают позиционированию компании и другим предварительно установленным критериям.",
      },
      {
        title: "Контент-маркетолог",
        text: "Изучает текущие каналы коммуникации. Находит релевантные каналы коммуникации с аудиторией, разрабатывает типы контента, составляет контент-план и инструкции по промотированию.",
      },
      {
        title: "Проектировщик",
        text: "Разрабатывает прототип. Продумывает структуру сайта и каждой страницы.",
      },
    ],
  },
];
export const brandPromotionStrategyData = [
  {
    title: "Как мы создаем из бизнеса бренд",
    text: [],
    bottomBox: [
      {
        title: "Исследование",
        text: "Анализируем бренды конкурентов, изучаем вашу аудиторию и бизнес.",
      },
      {
        title: "Позиционирование",
        text: "Выделяем преимущества бренда, формулируем суть и эмоциональный посыл.",
      },
      {
        title: "Айдентика",
        text: "Разрабатываем имя, знак и элементы фирменного стиля.",
      },
      {
        title: "Коммуникационная стратегия",
        text: "Разрабатываем стратегию продвижения бренда.",
      },
    ],
  },
  {
    title: "Нацеленность на прибыль",
    text: [
      "Бренд становится известным за счет визуальной и вербальной айденики и регулярной коммуникации с аудиторией бренда.",
    ],
    bottomBox: [
      {
        title: "Продукт",
        text: "Бренд укрепляет свои позиции за счет выявления и усиления преимуществ продукта, создания на их основе простого и понятного сообщения.",
      },
      {
        title: "Известность",
        text: "Бренд становится известным за счет запоминающейся визуальной и вербальной айденики и регулярной коммуникации с аудиторией бренда.",
      },
      {
        title: "Репутация",
        text: "Бренд выстраивает отношения с аудиторией через полезный и экспертный контент, который усиливает доверие к бренду и повышает репутацию.",
      },
    ],
  },
  {
    title: "Исследование",
    text: [
      "В начале работы над бренд-стратегией мы проводим исследование, которое ляжет в основу создания образа бренда, айдентики и стратегии продвижения.",
      "<span class='span__gradient'>Результат:</span> выявлены слабые стороны брендов конкурентов, сильные стороны вашего бизнеса, сегментирована целевая аудитория. Сформирована база для разработки позиционирования.",
    ],
    bottomBox: [
      {
        title: "Сильные стороны вашего бизнеса",
        text: "Оцениваем текущее состояние, находим возможности для развития бренда и продукта.",
      },
      {
        title: "Потребности аудитории",
        text: "Сегментируем аудиторию, определяем критерии выбора каждого сигмента.",
      },
      {
        title: "Слабые стороны конкурентов",
        text: "Находим сильные и слабые стороны брендов конкурентов, оцениваем их маркетинговую активность.",
      },
    ],
  },
  {
    title: "",
    text: [],
    bottomBox: [
      {
        title: "Суть бренда",
        text: "Формулируем суть бренда, ключевое сообщение (эмоциональный посыл, с которым будем обращаться к аудитории), миссию, ценности.",
      },
      {
        title: "Аудитория",
        text: "Определяем «незакрытые» потребности целевой аудитории на основе найденных предпочтений и критериев выбора продукта. Используем их в формировании бренд-стратегии.",
      },
      {
        title: "Преимущества",
        text: "Формируем понимание, какую уникальную выгоду получит потребитель и как будет себя чувствовать благодаря вашему продукту.",
      },
    ],
  },
  {
    title: "Коммуникационная бренд-стратегия",
    text: [
      "Создаем контент-план и рекламные креативы, собираем аудиторию бренда и разрабатываем медиаплан.",
    ],
    bottomBox: [
      {
        title: "Определение каналов коммуникации",
        text: "На основе исследования определяем наиболее подходящие каналы коммуникации для каждого сегмента аудитории digital-бренда.",
      },
      {
        title: "Контент",
        text: "Разрабатываем контент-план, который включает все рубрики, темы публикаций, сроки публикаций и способы их промотирования в каждом канале.",
      },
      {
        title: "Брендовая реклама",
        text: "Создаем рекламные креативы, которые будут доносить преимущества и ключевое сообщение аудитории бренда. Масштабируем баннеры на выбранные площадки.",
      },
      {
        title: "Редполитика",
        text: "Создаем редакционную политику, которая поможет сформировать единый стиль для всех коммуникаций.",
      },
    ],
  },
  {
    title: "Брендинг",
    text: [
      "На основе позиционирования, выбранного в бренд-стратегии, разрабатываем фирменный стиль бренда. Имя, знак и айдентику: вербальную и визуальную.",
    ],
    bottomBox: [
      {
        title: "Нейминг",
        text: "Разрабатываем имя, которое отразит специфику бренда и поможет формировать ассоциативные образы у аудитории. <p class = 'line__break'></p> Разрабатываем слоган или дескриптор, который усилит вербальный посыл.",
      },
      {
        title: "Айдентика",
        text: "Создаем образ будущего бренда исходя из позиционирования. Разрабатываем знак, определяем фирменные цвета и шрифты. Брендируем каналы оффлайн- и онлайн-коммуникации. Запоминающийся стиль повышает узнаваемость вашего бренда.",
      },
    ],
  },
  {
    title: "Стратегия продвижения",
    text: [],
    bottomBox: [
      {
        title: "Аудитория бренда",
        text: "Создаем инструкции, как находить, накапливать и расширять цифровую аудиторию бренда для регулярной коммуникации через рекламу и контент.",
      },
      {
        title: "Медиаплан",
        text: "Разрабатываем план продвижения бренда: определяем ежемесячный бюджет по каждому каналу, видам контента и рекламы.",
      },
    ],
  },
  {
    title: "Результат<br/> бренд-стратегии",
    text: [
      "Вы получаете два документа, с помощью которых команда специалистов сможет создавать и поддерживать развитие бренда.",
    ],
    bottomBox: [
      {
        title: "Бренд-бук",
        text: "Данные исследования: анализ брендов конкурентов, сегменты аудитории.<p class = 'line__break'></p>Позиционирование бренда: описание бренда, преимущества, УТП, миссии, ценности, сообщения.<p class = 'line__break'></p>Айдентика: вербальная и визуальная.<p class = 'line__break'></p>Гайд по использованию фирменных элементов.",
      },
      {
        title: "Коммуникационная стратегия",
        text: "Каналы коммуникации для рекламы и контента.<p class = 'line__break'></p>Рекламные баннеры.<p class = 'line__break'></p>Руководство по созданию, сбору и расширению цифровой аудитории.<p class = 'line__break'></p>Контент-план: рубрики, темы, частота публикаций промотирования.<p class = 'line__break'></p>Инструкция по таргетингу: реклама и промотирование в социальных сетях.<p class = 'line__break'></p>Медиаплан: сроки запуска рекламы и контента, прогноз ежемесячного бюджета по каждому каналу.",
      },
    ],
  },
];
export const comprehensivePromotion = [
  {
    title: "Синергия каналов",
    text: [],
    bottomBox: [
      {
        title: "Комплексная работа",
        text: "Работаем с контекстной и таргетинговой рекламой, SEO-оптимизацией, торговыми площадками.",
      },
      {
        title: "Аналитика",
        text: "Анализируем каждый канал продвижения. Оцениваем эффективность в качественных обращениях.",
      },
      {
        title: "Оптимизация",
        text: "На основе данных аналитики перераспределяем бюджеты на эффективные каналы.",
      },
    ],
  },
  {
    title: "Наш подход",
    text: [
      "По нашему опыту, основа эффективного продвижения — это комплексная работа, аналитика, оптимизация и качественный рекламный креатив.",
      "Текстовый контент должен доносить рекламное сообщение на языке, понятном целевой аудитории. Визуальный контент — быть аккуратным и единым для всех каналов коммуникации. В итоге повышается узнаваемость компании в различных каналах и повышается кликабельность объявлений.",
    ],
    bottomBox: [
      {
        title: "Комплексная работа",
        text: "Тестируем различные рекламные каналы. Это позволяет увеличить охват и понять, откуда можно привлекать максимально дешевые заявки.",
      },
      {
        title: "Аналитика",
        text: "Мы анализируем рекламные кампании от первого взаимодействия до обращения — используем Power BI, чтобы отслеживать эффективность каждого канала и рекламной кампании. Эти данные помогают оптимизировать рекламные бюджеты и приводить только качественные обращения.",
      },
      {
        title: "Качественный контент",
        text: "Найти целевую аудиторию в рекламных каналах недостаточно. Нужно привлечь ее внимание и правильно донести смысл рекламного сообщения. Поэтому мы уделяем внимание качественному текстовому и визуальному контенту.",
      },
      {
        title: "Оптимизация",
        text: "Также в аналитике отслеживаем влияние одних каналов продвижения на другие. Это помогает нам выстроить их активное взаимодействие.",
      },
    ],
  },
  {
    title: "Какие каналы используем",
    text: [
      "Каналы продвижения — это ресурсы и площадки для коммуникаций с аудиторией в разных форматах. Реклама и органическая выдача в поисковиках, популярные медийные и социальные сети.",
    ],
    bottomBox: [
      {
        title: "Контекстная реклама",
        text: "Позволяет привлечь на ваш сайт максимально целевую аудиторию, интересующуюся конкретными моделями/брендами/категориями товаров в поисковиках Яндекс, Google. А также вернуть на сайт пользователей, не завершивших процесс покупки или для повторных продаж.",
      },
      {
        title: "Таргетированная реклама",
        text: "Помогает приводить качественные обращения. Для новых продуктов помогает сформировать спрос и донести до целевой аудитории информацию о горячих скидках, акциях, распродажах и выгодных условиях через социальные сети. А также сформировать спрос, если ваш продукт неизвестен.",
      },
      {
        title: "SEO-оптимизация",
        text: "Помогает людям находить ваш продукт в поисковиках, а бизнесу зарабатывать больше. Улучшается ранжирование сайта, и повышается целевой трафик. Как следствие, увеличивается количество заявок и покупок с сайта.",
      },
      {
        title: "Аналитика",
        text: "Позволяет определить наиболее рентабельные источники обращений. А также передать эти данные в CRM для аналитики продаж и в системы оптимизации конверсий для снижения стоимости заявки.",
      },
      {
        title: "Торговые площадки",
        text: "Позволяют охватить аудиторию, которая ищет конкретные модели/бренды товаров на товарных агрегаторах. Чаще всего такая аудитория очень заинтересована в покупке, а стоимость её привлечения может быть значительно дешевле, чем в контекстной или таргетированной рекламе.",
      },
    ],
  },
  {
    title: "Результаты",
    text: [],
    bottomBox: [
      {
        title: "Эффективные каналы",
        text: "На основе данных о продажах понимаем, какие каналы работают лучше остальных. Делаем их основой для привлечения клиентов",
      },
      {
        title: "Развитие бренда",
        text: "Накапливаем цифровую аудиторию бренда, которая узнает и лояльно относится к вашему продукту.",
      },
      {
        title: "Рентабельность",
        text: "Делаем продвижение во много раз рентабельней, чем у конкурентов.<p class = 'line__break'></p>Это позволяет бизнесу быстрее и легче развиваться.",
      },
    ],
  },
  {
    title: "Команда",
    text: [],
    bottomBox: [
      {
        title: "Контекстолог",
        text: "Анализирует рекламу конкурентов, продумывает стратегию. Настраивает и ведет кампании. Корректирует ставки, подключает сервисы для автоматизации.",
      },
      {
        title: "Таргетолог",
        text: "Проводит исследование конкурентов и аудитории, продумывает стратегию. Настраивает и ведет кампании. Корректирует ставки, подключает сервисы для автоматизации.",
      },
      {
        title: "Специалист по SEO",
        text: "Оценивает текущее состояние сайта и проводит анализ конкурентов, разрабатывает и реализует стратегию продвижения и вывода сайта в ТОП поисковой выдачи, анализирует эффективность работ.",
      },
      {
        title: "Аналитик",
        text: "Подключает и настраивает системы аналитики. Создаёт data-pipeline и автоматические отчёты в Power BI. Даёт рекомендации по оптимизации рекламных кампаний.",
      },
      {
        title: "Дизайнер",
        text: "Создает рекламные баннеры для контекстной и таргетированной рекламы.",
      },
    ],
  },
];
// {
//   title: "",
//    text: [],
//   bottomBox: [
//     {
//       title: "",
//       text: "",
//     },
//     {
//       title: "",
//       text: "",
//     },
//     {
//       title: "",
//       text: "",
//     },
//   ],
// },
