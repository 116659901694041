import { SvgBox, TelegramText } from "./styles";

const Telegram = () => {
  return (
    <SvgBox>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <g clip-path="url(#clip0_881_12949)">
          <circle cx="15" cy="15" r="14" fill="white" />
          <path
            d="M19.9051 21.8806L22.3661 10.279C22.4665 9.78795 22.4079 9.43638 22.1903 9.22433C21.9727 9.01228 21.6853 8.97321 21.3281 9.10714L6.86384 14.6819C6.54018 14.8047 6.31975 14.9442 6.20257 15.1004C6.08538 15.2567 6.07143 15.4046 6.16071 15.5441C6.25 15.6836 6.42857 15.7924 6.69643 15.8705L10.3962 17.0257L18.9844 11.6183C19.2188 11.4621 19.3973 11.4286 19.5201 11.5179C19.5982 11.5737 19.5759 11.6574 19.4531 11.769L12.5056 18.0469L12.2377 21.8638C12.4944 21.8638 12.7455 21.7411 12.9911 21.4955L14.7991 19.7545L18.5491 22.5167C19.2634 22.9185 19.7154 22.7065 19.9051 21.8806ZM30 15C30 17.0312 29.6038 18.9732 28.8114 20.8259C28.019 22.6786 26.9531 24.2746 25.6138 25.6138C24.2746 26.9531 22.6786 28.019 20.8259 28.8114C18.9732 29.6038 17.0312 30 15 30C12.9688 30 11.0268 29.6038 9.17411 28.8114C7.32143 28.019 5.72545 26.9531 4.38616 25.6138C3.04687 24.2746 1.98103 22.6786 1.18862 20.8259C0.396205 18.9732 0 17.0312 0 15C0 12.9688 0.396205 11.0268 1.18862 9.17411C1.98103 7.32143 3.04687 5.72545 4.38616 4.38616C5.72545 3.04687 7.32143 1.98103 9.17411 1.18862C11.0268 0.396205 12.9688 0 15 0C17.0312 0 18.9732 0.396205 20.8259 1.18862C22.6786 1.98103 24.2746 3.04687 25.6138 4.38616C26.9531 5.72545 28.019 7.32143 28.8114 9.17411C29.6038 11.0268 30 12.9688 30 15Z"
            fill="#0099E8"
          />
        </g>
        <defs>
          <clipPath id="clip0_881_12949">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <TelegramText>Telegram</TelegramText>
    </SvgBox>
  );
};

export default Telegram;
