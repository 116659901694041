import { SvgBox, WhatsAppText } from "./styles";

const WhatsApp = () => {
  return (
    <SvgBox>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <g clip-path="url(#clip0_881_12942)">
          <circle cx="15" cy="15" r="14" fill="white" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.9999 0.814458C22.7442 0.808029 29.2006 7.27731 29.2006 15.0066C29.2006 22.7423 22.7442 29.1923 14.9999 29.188C12.9648 29.1891 10.9531 28.7536 9.10062 27.9109L2.07205 29.1816C1.88548 29.2158 1.69318 29.1998 1.5148 29.1353C1.33642 29.0708 1.17836 28.9601 1.05676 28.8146C0.935161 28.669 0.854386 28.4938 0.822686 28.3068C0.790987 28.1198 0.8095 27.9277 0.876333 27.7502L2.89062 22.3973C1.53358 20.17 0.814552 17.6126 0.812048 15.0045C0.81419 7.27517 7.26419 0.818744 14.9999 0.814458ZM22.1249 21.0345C20.5456 22.3116 18.1006 23.6016 15.7628 22.1016C12.6985 20.1323 9.98562 17.5459 8.01848 14.4795C6.86348 12.6837 7.9499 9.80803 9.57419 8.05517C10.2213 7.35446 11.2885 7.46589 11.9935 8.10874L13.4763 9.46303C13.9263 9.87446 13.9435 10.5902 13.6628 11.1345C13.2192 11.9873 12.9578 13.1337 13.5063 13.9887C14.1963 15.0602 15.0449 16.1166 17.072 16.7316C17.6849 16.9202 18.4713 16.8387 19.1399 16.5687C19.6992 16.3416 20.4085 16.3437 20.822 16.7852L22.202 18.253C22.9628 19.063 22.9885 20.3359 22.1249 21.0345Z"
            fill="#11C629"
          />
        </g>
        <defs>
          <clipPath id="clip0_881_12942">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <WhatsAppText>WhatsApp</WhatsAppText>
    </SvgBox>
  );
};

export default WhatsApp;
