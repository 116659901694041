import styled from "styled-components";

export const PageImg = styled.img`
//width: 80vw;   //уменьшение ширины изображения на 20% от ширины окна просмотра
//height: auto; 
//align-self: center;
`;

export const StyledCasePage = styled.main`
  display: flex;
  flex-direction: column;
`;



// export const PageImgWrapper = styled.div`
//    padding-left: calc(50% - 581px);
//   padding-right: calc(50% - 601px); 
// `;

