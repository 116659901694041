const VkLinkWhite = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <g id="Group_2">
          <path
            id="XMLID_11_"
            d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
            fill="white"
          />
        </g>
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2446 28.7726H20.8143C20.8143 28.7726 21.2885 28.7206 21.5306 28.4596C21.7534 28.22 21.7463 27.7701 21.7463 27.7701C21.7463 27.7701 21.7156 25.6638 22.6932 25.3536C23.6568 25.048 24.8943 27.3893 26.206 28.2895C27.1978 28.9708 27.9515 28.8214 27.9515 28.8214L31.4585 28.7726C31.4585 28.7726 33.2932 28.6596 32.4233 27.2171C32.352 27.0991 31.9167 26.1501 29.8157 24.1999C27.6167 22.1588 27.9112 22.489 30.5601 18.9583C32.1734 16.8082 32.8183 15.4955 32.6169 14.9333C32.4247 14.3978 31.2382 14.5393 31.2382 14.5393L27.2894 14.5639C27.2894 14.5639 26.9967 14.524 26.7796 14.6537C26.5675 14.781 26.4309 15.0776 26.4309 15.0776C26.4309 15.0776 25.8059 16.7415 24.9724 18.1565C23.214 21.1424 22.511 21.3 22.2236 21.1146C21.5552 20.6825 21.722 19.3787 21.722 18.4524C21.722 15.5589 22.1609 14.3525 20.8674 14.0402C20.4382 13.9364 20.1223 13.868 19.0246 13.8569C17.6156 13.8423 16.4231 13.8612 15.7478 14.192C15.2986 14.412 14.9521 14.9022 15.1631 14.9304C15.4241 14.9653 16.0152 15.0898 16.3286 15.5165C16.7332 16.067 16.719 17.3034 16.719 17.3034C16.719 17.3034 16.9514 20.7096 16.176 21.1328C15.6437 21.423 14.9136 20.8304 13.346 18.1216C12.5427 16.734 11.9363 15.2003 11.9363 15.2003C11.9363 15.2003 11.8193 14.9136 11.6108 14.7603C11.3577 14.5746 11.004 14.5154 11.004 14.5154L7.25163 14.54C7.25163 14.54 6.68833 14.5557 6.48154 14.8006C6.29758 15.0185 6.46693 15.4691 6.46693 15.4691C6.46693 15.4691 9.40464 22.3421 12.731 25.8057C15.781 28.9812 19.2446 28.7726 19.2446 28.7726Z"
          fill="#010135"
        />
      </g>
    </svg>
  );
};

export default VkLinkWhite;
