import { Container, ImgBox, TextBox, TextBoxTitle } from "./styles";

const Influence = () => {
  return (
    <Container>
      <TextBox>
        <p>
          Нас окружает информационный шум. Реклама, push-уведомления, вывески —
          мы научились не обращать внимания на попытки продать нам очередной
          товар.
        </p>
        <p>
          <b>
            Контент-маркетинг вместо навязывания
            <br /> начинает диалог.
          </b>
          Компания рассказывает о себе и о продукте, делится полезной
          информацией. Поэтому
          <b> клиенты начинают доверять бренду и покупать</b>
          <br /> у него.
        </p>
        <TextBoxTitle>
          Контент-маркетинг влияет на бренд и на продажи
        </TextBoxTitle>
        <p>
          Он привлекает людей на вашу сторону. Клиенты сами приводят клиентов,
          которые воодушевлены и хотят сотрудничать с вами. А реклама
          в социальных сетях, публикации в СМИ приносят горячую аудиторию. Эти
          пользователи быстрее доходят до принятия решения о покупке.
        </p>
      </TextBox>
      <ImgBox></ImgBox>
    </Container>
  );
};

export default Influence;
