export const headerSectionsName = [
  { name: "О компании", url: "/", pathname: "/" },
  { name: "Услуги", url: "/services", pathname: "/services" },
  { name: "Проекты", url: "/cases", pathname: "/cases" },
  { name: "Блог", url: "/Blogs", pathname: "/Blogs" },
  //заменил отдельной кнопкой для скролл-навигации по HashLink:
  //{ name: "Контакты", url: "/contacts", pathname: "/contacts" },  
];
export const weDoIteText = [
  {
    name: "стратегия",
    title: "Разработка маркетинговой стратегии",
    text: "Создаем твердую, детально проработанную маркетинговую стратегию с учетом особенностей рынка.",
    width: "380px",
  },
  {
    name: "Реализация",
    title: "Реализация маркетинговой стратегии", 
    text: "Задействуем необходимых для реализации специалистов, поэтапно контролируя выполнение работ.",
    width: "380px",
  },
  {
    name: "Подсчет показателей",
    title: "Подсчет показателей, окупаемости по итогам месяца",
    text: "Подсчитываем показатели KPI по итогам месяца. Считаем окупаемость стратегии. Понимаем стоимость лида и стоимость клиента.",
    width: "380px",
  },
  {
    name: "Корректировка",
    title: "Корректировка стратегии с целью увеличения КПД",
    text: "Корректируем стратегию, учитывая полученные в ходе анализа данные, чтобы повысить КПД маркетинга.",
    width: "380px",
  },
];


export const TariffsDataText = [
  {
    width: "380px",
    quantity: "Доступно 3 шт",
    name: "68 000 ₽",
    employees: "4 сотрудника",
    services: ["Маркетинговая стратегия", "Сайт", "SEO"],
    title: "Ваш маркетолог-эксперт на аутсорсе",
    points: [
      "Продвинем сайт на первые страницы поисковой выдачи Яндекс, до 30 запросов",
      "В первый месяц проведем аудит продающей способности сайта и составим ТЗ",
      "20 часов поддержки сайта ежемесячно (правки сайта, доработки, написание и публикация материалов)",
      "Ежемесячный детальный отчет и план работ на следующий месяц",
      "Консультация до 3-х часов по вопросам продвижения"
    ],
  },
  {
    width: "380px",
    quantity: "Доступно 2 шт",
    name: "89 000 ₽",
    employees: "5 сотрудников",
    services: ["Маркетинговая стратегия", "SEO", "Реклама", "Сайт", "Копирайтинг" ],
    title: "Загрузим ваш отдел продаж под крышку",
    points: [
      "Продвинем сайт на первые страницы поисковой выдачи Яндекс, до 50 запросов",
      "Запустим рекламные кампании ( Яндекс. Директ, VK, Telegram) с общим бюджетом до 350 000 р/мес",
      "Составим продающие тексты для сайта с уникальностью 95%",
      "Ежемесячный аудит сайта на предмет улучшения конверсии и составление ТЗ",
      "Ежемесячный технический аудит сайта на предмет улучшения работоспособности и скорости сайта и составление ТЗ",
      "10 уникальных тематических статей ежемесячно",
      "30 часов поддержки сайта ежемесячно (правки сайта, доработки, написание и публикация материалов)",
      "Ежемесячные детальные отчеты и план работ на следующий месяц",
      "Консультация до 5-ти часов по вопросам продвижения",
      "Прикрепленный дежурный специалист по рекламе и SEO продвижению"
    ],
  },
  {
    width: "380px",
    quantity: "Доступно 1 шт",
    name: "259 000 ₽",
    employees: "7 сотрудников",
    services: ["Маркетинговая стратегия", "SEO", "Реклама", "Копирайтинг", "Сайт", "Email-рассылки", "WhatsApp-рассылки" ],
    title: "Вырастим ваш бизнес кратно",
    points: [
      "Продвинем сайт на первые страницы поисковой выдачи Яндекс, до 100 запросов",
      "Запустим рекламные кампании (Яндекс. Директ, VK, Telegram) с общим бюджетом до 1 250 000 р/мес",
      "Проведем аудит сайта и создадим фирменный стиль",
      "50 часов доработок сайта или создание нового многостраничного сайта",
      "30  уникальных тематических статей ежемесячно",
      "Ежемесячные детальные отчеты и план работ на следующий месяц",
      "Консультация до 12-ти часов по вопросам продвижения",
      "Еженедельное тестирование сайта",
      "Создадим продающие тексты для рассылок и сайта",
      "Найдем самые горячие базы для рассылок",
      "Проведем до 15 рассылок по базам",
      "Выделим персонального аккаунт-менеджера"
    ],
    isSpecial: true,
  },
];
