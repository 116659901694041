import { WrongPage } from "./styles";

interface NotFoundProps {
    
}
 
const NotFound: React.FunctionComponent<NotFoundProps> = () => { //Страничка 404 
    return ( <div>Нет нет нет, переписывай код!</div> );
}
 
export default NotFound;